import Vue from "vue";
import Vuex from "vuex";
import auth from "@/modules/auth/store";
import superAdminStore from "@/modules/superAdmin/store";
import adminStore from "@/modules/admin/store";
import buyer from "@/modules/buyer/store.js";
import validation from "@/modules/shared/validation/store";
import home from "@/modules/home/store";
import vehicle from "@/modules/vehicle/store";

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    superAdminStore,
    buyer,
    auth,
    adminStore,
    validation,
    home,
    vehicle,
  },
  plugins: [
    createPersistedState({
      paths: ["adminStore.vehicles.listStore", "home"],
    }),
  ],
  strict: true,
});
