const generalMixin = {
  data() {
    return {
      isBusy: false,
    };
  },
  computed: {
    loginData() {
      return this.$store.state.auth.loginStore.profile.data;
    },
    user() {
      return this.loginData ? this.loginData.user : null;
    },
    userIsSuperAdmin() {
      return this.checkUserRole("ROLE_SUPER_ADMIN");
    },
    userIsAdmin() {
      return this.checkUserRole("ROLE_ADMIN");
    },
    userIsAgent() {
      return this.checkUserRole("ROLE_AGENT");
    },
    userIsClient() {
      return this.checkUserRole("ROLE_CLIENT");
    },
    userIsBuyer() {
      return this.checkUserRole("ROLE_BUYER");
    },
    userIsRetail() {
      return this.checkUserRole("ROLE_RETAIL");
    },
    userIsWholesale() {
      return this.checkUserRole("ROLE_WHOLESALE");
    },
    userHasCompany() {
      if (!this.user) {
        return false;
      }
      return this.user?.company ? true : false;
    },
    companyIsPending() {
      if (
        this.userIsAdmin
      ) {
        return false;
      }
      if (!this.user) {
        return false;
      } else {
        let isPending = false;
        if (
          this.user.company &&
          this.user.company.status === 2
        ) {
          isPending = true;
        }
        return isPending;
      }
    },
  },
  methods: {
    checkUserRole(userRole) {
      if (!this.user) {
        return false;
      } else {
        const { roles } = this.user;
        return roles.find((role) => role.name === userRole);
      }
    },
    makeToast(title, text, type) {
      this.$root.$bvToast.toast(text, {
        title: title,
        autoHideDelay: 5000,
        variant: type,
        solid: true,
      });
    },
    showModal(id) {
      this.$bvModal.show(id);
    },
    closeModal(modalId, callback = null) {
      this.$bvModal.hide(modalId);
      if (callback) callback();
    },
    showConfirmDeleteBox() {
      return this.$bvModal.msgBoxConfirm(
        "Please confirm that you want to delete the item.",
        {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okTitle: "YES",
          okVariant: "success",
          cancelTitle: "NO",
          cancelVariant: "danger",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        }
      );
    },

    showConfirmBuyCar() {
      return this.$bvModal.msgBoxConfirm("Are you sure to buy this car?", {
        title: "Please Confirm",
        size: "sm",
        buttonSize: "sm",
        okVariant: "success",
        okTitle: "YES",
        cancelTitle: "NO",
        cancelVariant: "danger",
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true,
      });
    },
    showAcceptOfferConfirmation() {
      return this.$bvModal.msgBoxConfirm("Are you sure to accept this offer?", {
        title: "Please Confirm",
        size: "sm",
        buttonSize: "sm",
        okVariant: "success",
        okTitle: "YES",
        cancelTitle: "NO",
        cancelVariant: "danger",
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true,
      });
    },
  },
};

export { generalMixin };
