<template>
  <div>
    <!-- Upload photos -->
    <b-row>
      <b-col sm="12" md="6" lg="4" xl="4">
        <!--   <b-button @click="openModal" class="shadow" variant="purple">
          <b-row class="center-block mt-1">
            <img
              src="@/assets/img/icons/upload_photos_img.png"
              alt="uploadPhotos"
              style="max-width: 40px"
              class="mr-3"
            />
            <h3>Upload Photos</h3>
          </b-row>
        </b-button> -->
        <div>
          <b-button class="ml-3" variant="purple" @click="openModal">
            <b-icon icon="plus-circle" />
          </b-button>
          <b-button variant="primary" class="mx-2" @click="openEditPartsImage">
            <b-icon icon="pencil-square"></b-icon>
          </b-button>
        </div>
      </b-col>
    </b-row>
    <!-- Photos list -->
    <b-row>
      <!-- Internal photos -->
      <b-col class="mt-3 mb-3" sm="12" md="6" lg="4" xl="4">
        <b-card class="shadow">
          <div class="mb-2 mt-1 text-center">
            <h5>{{ $t("photosUploadedManually") }}</h5>
          </div>
        </b-card>
        <div
          style="width: 100%"
          :style="
            vehicleHasPartsProps && !vehicleHasPartsProps.length
              ? 'height: 3rem;'
              : 'height: 33rem; overflow-y: scroll'
          "
        >
          <b-list-group>
            <b-list-group-item
              v-if="vehicleHasPartsProps && !vehicleHasPartsProps.length"
            >
              <div>
                Hey! Looks like you are the first person to take some pics
              </div>
            </b-list-group-item>

            <b-list-group-item v-for="(p, i) in vehicleHasPartsProps" :key="i">
              <div class="vehicle-part-content d-flex justify-content-between">
                <div class="">
                  <p
                    class="vehicle-part-name h5 d-flex justify-content-between"
                  >
                    <span>{{ p.part.name }}</span>
                    <b-button
                      :disabled="isLoadingDelete"
                      variant="danger"
                      size="sm"
                      @click="deletePic(p)"
                      v-b-tooltip.hover
                      title="Delete image"
                    >
                      <b-icon variant="white" scale="0.9" icon="trash" />
                    </b-button>
                  </p>
                  <img
                    width="200"
                    :src="
                      imgsNotFound.find((e) => e === i)
                        ? require('@/assets/img/image_not_found.png')
                        : p.photoThumbnail
                    "
                    :alt="p.part.name"
                    class="vehicle-part-img mr-3 img-thumbnail"
                    @click="onInputImageToPreview(p.photo, p.part.name)"
                    @error="errorLoadImg(i)"
                  />
                </div>
                <div class="vehicle-part-delete d-flex align-items-center">
                  <!--    <b-button
                    @click="deletePhoto(p.id)"
                    :disabled="deletePhotosStatus === 'fetching'"
                    variant="light"
                  >
                    <b-icon variant="secondary" scale="2" icon="x" />
                  </b-button> -->
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
        </div>
      </b-col>
      <!-- External photos -->
      <b-col class="mt-3 mb-3" sm="12" md="6" lg="4" xl="4">
        <b-card class="shadow">
          <div class="mb-2 mt-1 text-center">
            <h5>Photos uploaded by Condition Report</h5>
          </div>
        </b-card>
        <div
          style="width: 100%"
          :style="
            vehicleExternalImagesProps && !vehicleExternalImagesProps.length
              ? 'height: 3rem;'
              : 'height: 33rem; overflow-y: scroll'
          "
        >
          <b-list-group>
            <b-list-group-item
              v-if="
                vehicleExternalImagesProps && !vehicleExternalImagesProps.length
              "
            >
              <div>
                Hey! Looks like you are the first person to take some pics
              </div>
            </b-list-group-item>
            <!-- External images -->
            <b-list-group-item
              v-for="(p, i) in vehicleExternalImagesProps"
              :key="'external img' + i"
            >
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-row">
                  <img
                    width="200"
                    :src="p.photo"
                    class="mr-3 img-thumbnail"
                    @click="onInputImageToPreview(p.photo, null)"
                  />
                </div>
                <div class="d-flex align-items-center">
                  <!--   <b-button
                    @click="deleteExternalPhoto(p.id)"
                    :disabled="deletePhotosStatus === 'fetching'"
                    variant="light"
                  >
                    <b-icon variant="secondary" scale="2" icon="x" />
                  </b-button> -->
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
        </div>
      </b-col>
      <b-col class="mt-3" sm="12" md="6" lg="4" xl="4">
        <b-card class="preview shadow text-center">
          <h5>Preview</h5>
          <div
            v-if="
              !vehicleHasPartsProps.length && !vehicleExternalImagesProps.length
            "
            class="mt-3"
          >
            <span> Nothing selected to show </span>
          </div>
          <div v-else>
            <b-row>
              <b-col>
                <img
                  v-if="imgError"
                  v-bind:src="imgError"
                  class="img-fluid img-thumbnail"
                  alt=""
                />
                <img
                  v-else
                  width="330"
                  class="mt-3 carousel-item active main-img img-thumbnail"
                  v-bind:src="imageToPreview"
                  alt="preview"
                  @error="errorLoadImg(null, true)"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <h4 class="mt-3">{{ partNameToPreview }}</h4>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
      <!--       <UploadModal :vehicleId="vehicleId" />
 -->
      <b-modal
        @show="show"
        centered
        no-close-on-backdrop
        :id="modalId"
        ref="modal"
        title="New pic"
      >
        <div v-if="vehicleParts">
          <div v-if="imageToUpload">
            <label>Preview</label>
            <img
              class="img-thumbnail main-img"
              :src="imageToUpload"
              alt="preview"
            />
          </div>
          <b-form-group label="Image">
            <b-form-file
              @input="onInputImageToUpload"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              v-model="photo"
              accept="image/*"
              multiple
            ></b-form-file>
          </b-form-group>
          <b-form-group label="Part">
            <b-form-select v-model="partsId">
              <b-form-select-option
                v-for="(p, i) of vehicleParts.externalPart"
                :key="i"
                :value="p.id"
              >
                {{ p.name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </div>
        <template v-slot:modal-footer="{}">
          <div class="d-flex justify-content-end">
            <b-button
              :disabled="!photo"
              variant="success"
              @click="openPreviewImagesModal()"
            >
              <b-spinner
                v-if="uploadPhotoStatus === 'fetching'"
                small
                variant="white"
              />
              <b-icon v-else icon="plus"></b-icon>
            </b-button>
          </div>
        </template>
      </b-modal>
      <b-modal
        v-model="showUploadProgress"
        title="Uploading Images"
        hide-footer
        no-close-on-backdrop
      >
        <p class="my-4">
          Uploading {{ uploadedImages }} of {{ totalImages }} images
        </p>
        <b-progress :value="uploadProgress" max="100" class="mb-2"></b-progress>
      </b-modal>
    </b-row>

    <PreviewImages
      v-if="showPreviewImagesModal"
      ref="previewImages"
      :vehicleImages="imagePreview"
      @onConfirmImages="onConfirmImages"
      :isUpdatePreviewImages="isUpdatePreviewImages"
      :showModal="showPreviewImagesModal"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  GET_VEHICLE_PARTS,
  DELETE_PHOTO,
  DELETE_EXTERNAL_PHOTO,
} from "./actions";
import { registryVehicleMixin } from "../mixins";
import PreviewImages from "./PreviewImages.vue";
import { DELETE_VEHICLE_PHOTO } from "../actions";
export default {
  props: ["vehicleId", "vehicleHasPartsProps", "vehicleExternalImagesProps"],
  components: { PreviewImages },
  mixins: [registryVehicleMixin],
  data() {
    return {
      imgError: null,
      imgsNotFound: [],
      imageToPreview: null,
      partNameToPreview: "",
      showPreviewImagesModal: false,
      imagePreview: [],
      imagesConfirmed: [],
      isUpdatePreviewImages: false,
      imageToUpload: "",
      modalId: "modal-add-pic",
      partsId: null,
      photo: null,
      showUploadProgress: false,
      totalImages: 0,
      uploadedImages: 0,
      uploadProgress: 0,
    };
  },
  computed: {
    ...mapState({
      deletePhotosStatus: (state) =>
        state.adminStore.vehicles.carRegistry.uploadVehiclePhotosStore
          .deletePhoto.status,
      vehicleParts: (state) =>
        state.adminStore.vehicles.carRegistry.uploadVehiclePhotosStore.parts
          .data,
      uploadPhotoStatus: (state) =>
        state.adminStore.vehicles.carRegistry.uploadVehiclePhotosStore
          .uploadPhoto.status,
      isLoadingDelete: (state) =>
        state.adminStore.vehicles.carRegistry.uploadVehiclePhotosStore
          .deletePhoto.status === "fetching",
    }),
  },
  methods: {
    openModal() {
      this.$bvModal.show("modal-add-pic");
    },
    closeUploadImageModal() {
      this.$bvModal.hide("modal-add-pic");
    },
    getVehicleParts() {
      this.$store
        .dispatch(GET_VEHICLE_PARTS)
        .then(() => {})
        .catch(() => {
          this.$root.$bvToast.toast("The Vehicle parts could not be obtained", {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    onInputImageToPreview(url, partName) {
      this.imgError = null;
      this.imageToPreview = url;
      this.partNameToPreview = partName;
    },
    showConfirmDeleteBox() {
      return this.$bvModal.msgBoxConfirm("Please Confirm", {
        title: "Please confirm that you want to delete the item.",
        size: "sm",
        buttonSize: "sm",
        okVariant: "danger",
        okTitle: "YES",
        cancelTitle: "NO",
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true,
      });
    },
    photoDeleteSuccessMessage() {
      this.$root.$bvToast.toast("Photo deleted successfully", {
        title: "Success",
        variant: "success",
      });
    },
    photoDeleteErrorMessage() {
      this.$root.$bvToast.toast("The photo could not be deleted", {
        title: "Warning",
        variant: "warning",
      });
    },
    async deletePhoto(photoId) {
      const value = await this.showConfirmDeleteBox();
      if (!value) {
        return;
      } else {
        this.$store
          .dispatch(DELETE_PHOTO, photoId)
          .then(() => {
            this.getVehicleByVin();
            this.photoDeleteSuccessMessage();
          })
          .catch(() => {
            this.photoDeleteErrorMessage();
          });
      }
    },
    async deleteExternalPhoto(photoId) {
      const value = await this.showConfirmDeleteBox();
      if (!value) {
        return;
      } else {
        this.$store
          .dispatch(DELETE_EXTERNAL_PHOTO, photoId)
          .then(() => {
            this.getVehicleByVin();
            this.photoDeleteSuccessMessage();
          })
          .catch(() => {
            this.photoDeleteErrorMessage();
          });
      }
    },
    errorLoadImg(i = null, previewImg = false) {
      this.imgsNotFound.push(i);
      if (previewImg)
        this.imgError = require("@/assets/img/image_not_found.png");
    },
    newImageToPreview(photo) {
      this.imageToPreview = photo;
    },
    openEditPartsImage() {
      this.isUpdatePreviewImages = true;
      this.imagePreview = this.vehicleHasPartsProps.map((p) => {
        return {
          photo: p.photo,
          part: p.partsId,
          url: p.photoThumbnail,
          fileId: p.id,
        };
      });
      console.log(this.imagePreview);
      this.showPreviewImagesModal = true;
    },
    onConfirmImages(imagesWithPartId) {
      this.imagesConfirmed = imagesWithPartId;
      if (this.imagesConfirmed.length === 0) {
        this.makeToast(
          "Warning",
          "You should select at least one photo to continue",
          "warning"
        );
        return;
      }
      this.addPhoto();
    },
    show() {
      this.clearForm();
    },
    onInputImageToUpload(file) {
      const reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
      }
      reader.onloadend = () => {
        this.imageToUpload = reader.result;
      };
    },
    clearForm() {
      this.imageToUpload = null;
      this.partsId = null;
    },
    openPreviewImagesModal() {
      if (this.photo.length === 0) {
        this.makeToast(
          "Warning",
          "You should select at least one photo to continue",
          "warning"
        );
        return;
      }
      this.isUpdatePreviewImages = false;
      this.formatImagesToPreview();
      this.showPreviewImagesModal = true;
    },
    formatImagesToPreview() {
      this.imagePreview = [];

      Array.from(this.photo).forEach((file) => {
        const imageUrl = URL.createObjectURL(file);
        this.imagePreview.push({
          file: file,
          url: imageUrl,
        });
      });
    },
    async addPhoto() {
      console.log("addPhoto", this.imagesConfirmed);
      this.totalImages = this.imagesConfirmed.length;
      this.uploadedImages = 0;
      this.uploadProgress = 0;
      this.showUploadProgress = true;
      /*       const quantity = this.imagesConfirmed.length;
       */ if (
        (Array.isArray(this.photo) || this.isUpdatePreviewImages) &&
        this.imagesConfirmed.length > 0
      ) {
        for (let i = 0; i < this.imagesConfirmed.length; i++) {
          const data = new FormData();
          data.append("photo1", this.imagesConfirmed[i]?.image?.file);
          data.set("quantity", 1);
          data.set("vehiclesId", this.vehicleId);
          data.set("partsId", this.imagesConfirmed[i].id);
          data.set("id", this.imagesConfirmed[i]?.image?.fileId);
          await this.$store
            .dispatch("POST_UPLOAD_PHOTO", data)
            .then(() => {
              this.uploadedImages++;
              this.uploadProgress =
                (this.uploadedImages / this.totalImages) * 100; /* */
              /*
               */
              /*           this.clearForm();
               */
            })
            .catch(() => {
              this.$root.$bvToast.toast("The photo could not be uploaded", {
                title: "Warning",
                variant: "warning",
              });
            });
        }
        this.$root.$bvToast.toast("Photo assigned successfully", {
          title: "Success",
          variant: "success",
        });
        this.showUploadProgress = false;
        this.showPreviewImagesModal = false;
        this.isUpdatePreviewImages = false;
        this.closeUploadImageModal();
        this.getVehicleByVin();
      }

      /*     const data = new FormData();
      if (Array.isArray(this.photo)) {
        this.photo.forEach((element, index) => {
          data.append("photo" + (index + 1), element);
        });
      } else {
        data.append("photo", this.photo);
      }
      data.set("quantity", this.photo?.length || 1);
      data.set("vehiclesId", this.vehicleId);
      data.set("partsId", this.partsId);
      data.set("photo", this.photo); */
    },

    async deletePic(photo) {
      const value = await this.showConfirmDeleteBox();
      if (!value) {
        return;
      } else {
        this.$store
          .dispatch(DELETE_VEHICLE_PHOTO, { id: photo.id })
          .then(() => {
            this.getVehicleByVin();
            this.photoDeleteSuccessMessage();
          })
          .catch((error) => {
            console.log("error", error);
            this.photoDeleteErrorMessage();
          });
      }
    },
  },
  watch: {
    vehicleHasPartsProps(newData) {
      if (newData[0]) this.newImageToPreview(newData[0].photo);
    },
    vehicleExternalImagesProps(newData) {
      if (!this.vehicleHasPartsProps.length && newData[0])
        this.newImageToPreview(newData[0].photo);
    },
  },
  mounted() {
    if (this.vehicleHasPartsProps?.length) {
      this.imageToPreview = this.vehicleHasPartsProps[0].photo;
    } else if (this.vehicleExternalImagesProps?.length) {
      this.imageToPreview = this.vehicleExternalImagesProps[0].photo;
    }

    this.getVehicleParts();
  },
};
</script>

<style>
.center-block {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
@media (max-width: 300px) {
  .vehicle-part-content {
    padding-bottom: 45%;
  }
  .vehicle-part-delete {
    padding-left: 175px;
    margin-top: 25%;
  }
  .vehicle-part-img,
  .vehicle-part-name {
    position: absolute;
  }
  .vehicle-part-img {
    width: 170px;
  }
  .vehicle-part-name {
    margin-top: 66%;
  }
}
</style>
