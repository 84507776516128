import Vue from "vue";
import moment from "moment";
import numeral from "numeral";
Vue.filter("formatDateTime", function (value) {
  if (value) {
    return moment(String(value)).format("MMM Do YY, hh:mm:ss A");
  }
});
Vue.filter("formatTime", function (value) {
  if (value) {
    return moment(String(value), "hh:mm A").format("hh:mm A");
  }
});
Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(String(value)).format("MMM Do YY");
  }
});
Vue.filter("formatDateTimeInput", function (value) {
  if (value) {
    return moment(String(value)).format("YYYY-MM-DDTkk:mm");
  }
});
Vue.filter("toUpperCase", function (value) {
  if (value) {
    return value.toUpperCase();
  }
});

Vue.filter("toLowerCase", function (value) {
  if (value) {
    return value.toLowerCase();
  }
});

Vue.filter("truncate", (text, length, clamp) => {
  clamp = clamp || "...";
  var node = document.createElement("div");
  node.innerHTML = text;
  var content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
});

Vue.filter("currency", (value) => {
  return (
    "$" + (value / 1).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
  );
});

Vue.filter("formatNumber", (value) => {
  return numeral(value).format("0,0");
}
);
