import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    users: {
      status: null,
      data: [],
      error: null,
    },
    user: {
      status: null,
      data: null,
      error: null,
    },
  };
}

const actions = {
  [constants.GET_USERS_BUYER]: async ({ commit }, params) => {
    try {
      commit(constants.GET_USERS_BUYER);

      const response = await axios.get(`company/listUserByCompany`, {
        params: { ...params },
      });
      commit(constants.GET_USERS_BUYER_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_USERS_BUYER_ERROR, error);
      throw error.response;
    }
  },
  [constants.PUT_USER_BUYER]: async ({ commit }, params) => {
    try {
      commit(constants.PUT_USER_BUYER);

      const response = await axios.put(`user`, params);
      commit(constants.PUT_USER_BUYER_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_USER_BUYER_ERROR, error);
      throw error.response;
    }
  },

  [constants.DELETE_USER_BUYER]: async ({ commit }, params) => {
    try {
      commit(constants.DELETE_USER_BUYER);

      const response = await axios.delete(
        `company/deleteUserNotValidate?userId=${params.id}`
      );
      commit(constants.DELETE_USER_BUYER_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.DELETE_USER_BUYER_ERROR, error);
      throw error.response;
    }
  },

  [constants.PUT_USER_BUYER_PASSWORD]: async ({ commit }, params) => {
    try {
      commit(constants.PUT_USER_BUYER_PASSWORD);

      const response = await axios.put(`user/completeRegistration`, params);
      commit(constants.PUT_USER_BUYER_PASSWORD_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_USER_BUYER_PASSWORD_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.GET_USERS_BUYER]: (state) => {
    state.users.status = "fetching";
    state.users.error = null;
  },
  [constants.GET_USERS_BUYER_SUCCESS]: (state, data) => {
    state.users.status = "success";
    state.users.error = null;
    state.users.data = data;
  },
  [constants.GET_USERS_BUYER_ERROR]: (state, error) => {
    state.users.status = "error";
    state.users.error = error;
  },

  [constants.PUT_USER_BUYER]: (state) => {
    state.user.status = "fetching";
    state.user.error = null;
  },
  [constants.PUT_USER_BUYER_SUCCESS]: (state, data) => {
    state.user.status = "success";
    state.user.error = null;
    state.user.data = data;
  },
  [constants.PUT_USER_BUYER_ERROR]: (state, error) => {
    state.user.status = "error";
    state.user.error = error;
  },

  [constants.DELETE_USER_BUYER]: (state) => {
    state.user.status = "fetching";
    state.user.error = null;
  },
  [constants.DELETE_USER_BUYER_SUCCESS]: (state, data) => {
    state.user.status = "success";
    state.user.error = null;
    state.user.data = data;
  },
  [constants.DELETE_USER_BUYER_ERROR]: (state, error) => {
    state.user.status = "error";
    state.user.error = error;
  },

  [constants.PUT_USER_BUYER_PASSWORD]: (state) => {
    state.user.status = "fetching";
    state.user.error = null;
  },
  [constants.PUT_USER_BUYER_PASSWORD_SUCCESS]: (state, data) => {
    state.user.status = "success";
    state.user.error = null;
    state.user.data = data;
  },
  [constants.PUT_USER_BUYER_PASSWORD_ERROR]: (state, error) => {
    state.user.status = "error";
    state.user.error = error;
  },
};

export default {
  state: getDefaultState(),
  actions,
  mutations,
};
