import * as constants from "./actions";
import axios from "@/plugins/axios.js";
import componentStore from "./components/store";

function getDefaultState() {
  return {
    vehiclesInfo: {
      status: null,
      data: [],
      error: null,
      filters: {
        search: null,
        resourcesPerPage: 10,
        page: 1,
      },
    },
    vehicle: null,
  };
}

const actions = {
  [constants.GET_VEHICLES_INFO]: async ({ commit, state }) => {
    try {
      commit(constants.GET_VEHICLES_INFO);
      const payload = Object.entries(state.vehiclesInfo.filters).reduce(
        (a, [k, v]) => (v === null || v === "" ? a : { ...a, [k]: v }),
        {}
      );
      const response = await axios.get(`vehicle/getForTable`, {
        params: payload,
      });
      commit(constants.GET_VEHICLES_INFO_SUCCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_VEHICLES_INFO_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.GET_VEHICLES_INFO]: (state) => {
    state.vehiclesInfo.status = "fetching";
    state.vehiclesInfo.error = null;
  },
  [constants.GET_VEHICLES_INFO_SUCCCESS]: (state, data) => {
    state.vehiclesInfo.status = "success";
    state.vehiclesInfo.error = null;
    state.vehiclesInfo.data = data;
  },
  [constants.GET_VEHICLES_INFO_ERROR]: (state, error) => {
    state.vehiclesInfo.status = "error";
    state.vehiclesInfo.error = error;
  },
  [constants.SET_VEHICLE_INFO]: (state, data) => {
    state.vehicle = data;
  },
  [constants.SET_VEHICLES_INFO_FILTERS]: (state, filters) => {
    if (filters.reset) {
      state.vehiclesInfo.filters = {
        search: null,
        resourcesPerPage: 10,
        page: 1,
      };
    }
    if (filters.fields) {
      state.vehiclesInfo.filters = {
        ...state.vehiclesInfo.filters,
        ...filters.fields,
      };
    }
  },
};

export default {
  state: getDefaultState(),
  actions,
  mutations,
  modules: {
    componentStore,
  },
};
