import store from "@/store";
import { GET_VALIDATIONS } from "./actions";
const validationMixin = {
  data() {
    return {
      userItem: {},
      formValidation: {
        companyName: Boolean,
        taxid: Boolean,
        dealerLicense: Boolean,
      },
    };
  },
  computed: {
    companyValidations() {
      return store.state.validation.companyItem.validations;
    },
  },
  methods: {
    resetValidations() {
      this.formValidation.companyName = false;
      this.formValidation.taxid = false;
      this.formValidation.dealerLicense = false;
    },
    setValidations() {
      if (this.companyValidations) {
        this.formValidation.companyName = this.companyValidations.companyName;
        this.formValidation.taxid = this.companyValidations.taxid;
        this.formValidation.dealerLicense = this.companyValidations.dealerLicense;
      }
    },
    async getCompanyValidationInfo() {
      this.resetValidations();
      this.$store.commit("setCompanyItem", {});
      this.userItem = store.state.validation.itemUser;
      const payload = {
        userId: this.userItem.user.userDetails.id,
      };
      this.$store
        .dispatch(GET_VALIDATIONS, payload)
        .then(() => {
          this.setValidations();
        })
        .catch(() => {
          this.$root.$bvToast.toast("Warning", {
            title: "Warning",
            variant: "warning",
          });
        });
    },
  },
};
export { validationMixin };
