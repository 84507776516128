<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="10">
          <small class="text-muted mt-3">
            {{ conditionalReport.date | formatDateTime }} -
            {{ conditionalReport.user.lastName }}
            {{ conditionalReport.user.firstName }}
          </small>
        </b-col>
        <b-col cols="2">
          <div class="text-right">
            <span
              class="dot"
              :class="[conditionalReport.status ? 'dot-success' : 'dot-danger']"
            />
          </div>
        </b-col>
      </b-row>
      <div class="mt-3">
        <span
          class="cursor-pointer"
          @click="showConditionalReport(conditionalReport.url)"
        >
          Open Condition Report
        </span>
      </div>
    </b-card>
  </div>
</template>

<script>
export default {
  props: ["conditionalReport"],
  methods: {
    showConditionalReport(report) {
      window.open(report, "_blank");
    },
  },
};
</script>

<style></style>
