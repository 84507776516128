import store from "@/store";
import { AUTH_CHECK_TOKEN } from "@/modules/auth/checkToken/actions";

const refreshToken = localStorage.getItem("refresh-token");
const accessToken = localStorage.getItem("access-token");

export async function ifAuthenticated(to, from, next) {
  if (
    !store.state.auth.loginStore.profile.data &&
    refreshToken &&
    accessToken
  ) {
    store
      .dispatch(AUTH_CHECK_TOKEN)
      .then(() => {
        next();
      })
      .catch(() => {
        to.name === "home" ? next() : next({ name: "home" });
      });
  } else if (store.state.auth.loginStore.profile.data) {
    next();
  } else {
    to.name === "home" ? next() : next({ name: "home" });
  }
}

export async function ifRoleAuthenticated(to, from, next, roles) {
  if (
    !store.state.auth.loginStore.profile.data &&
    refreshToken &&
    accessToken
  ) {
    store
      .dispatch(AUTH_CHECK_TOKEN)
      .then(() => {
        if (roles) {
          const user = store.state.auth.loginStore.profile.data.user.roles.find(
            (r) => roles.includes(r.name)
          );
          if (user) {
            next();
          } else {
            next({ name: "home" });
          }
        }
      })
      .catch(() => {
        next({ name: "home" });
      });
  } else if (
    store.state.auth.loginStore.profile.data &&
    store.state.auth.loginStore.profile.data.user.roles.find((r) =>
      roles.includes(r.name)
    )
  ) {
    next();
  } else {
    next({ name: "home" });
  }
}
