<template>
  <b-button
    :disabled="recoverPasswordStatus === 'fetching'"
    variant="info"
    class="mt-1 text-center"
    size="sm"
    @click="resendPasswordEmail()"
  >
    <b-spinner
      v-if="recoverPasswordStatus === 'fetching'"
      small
      class="text-center"
    />
    <span v-else>Send email to set password</span>
  </b-button>
</template>

<script>
import { mapState } from "vuex";
import { POST_FORGOT_PASSWORD } from "@/modules/auth/forgotPassword/actions.js";
export default {
  props: ["email"],
  computed: {
    ...mapState({
      recoverPasswordStatus: (state) =>
        state.auth.forgotPasswordStore.forgotPassword.status,
    }),
  },
  methods: {
    resendPasswordEmail() {
      this.$store
        .dispatch(POST_FORGOT_PASSWORD, { email: this.email })
        .then(() => {
          this.$root.$bvToast.toast("Email sent", {
            title: "Success",
            variant: "success",
          });
        })
        .catch(() => {
          this.$root.$bvToast.toast("Email not sent", {
            title: "Warning",
            variant: "warning",
          });
        });
    },
  },
};
</script>

<style></style>
