import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    users: {
      status: null,
      data: [],
      error: null,
    },
  };
}

const actions = {
  [constants.GET_USERS]: async ({ commit }, params) => {
    try {
      commit(constants.GET_USERS);
      const response = await axios.get(`user`, { params: { ...params } });
      commit(constants.GET_USERS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_USERS_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.GET_USERS]: (state) => {
    state.users.status = "fetching";
    state.users.error = null;
  },
  [constants.GET_USERS_SUCCESS]: (state, data) => {
    state.users.status = "success";
    state.users.error = null;
    state.users.data = data;
  },
  [constants.GET_USERS_ERROR]: (state, error) => {
    state.users.status = "error";
    state.users.error = error;
  },
};

export default {
  state: getDefaultState(),
  actions,
  mutations,
};
