import * as constants from "./actions";
import axios from "@/plugins/axios.js";
import store from "@/store";

function getDefaultState() {
  return {
    vehicles: {
      status: null,
      data: [],
      error: null,
      filters: {
        resourcesPerPage: 6,
        page: 1,
      },
    },
    contactForm: {
      status: null,
      data: null,
      error: null,
    },
  };
}

const actions = {
  [constants.GET_PUBLIC_VEHICLES]: async (
    { commit, state },
    getGlobalVehicleFilters = null
  ) => {
    try {
      commit(constants.GET_PUBLIC_VEHICLES);
      const payload = Object.entries(
        getGlobalVehicleFilters
          ? store.state.adminStore.vehicles.listStore.vehicles.filters
          : state.vehicles.filters
      ).reduce(
        (a, [k, v]) => (v === null || v === "" ? a : { ...a, [k]: v }),
        {}
      );
      const response = await axios.get(`vehicle/public`, { params: payload });

      commit(constants.GET_PUBLIC_VEHICLES_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_PUBLIC_VEHICLES_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_CONTACT_FORM]: async ({ commit }, payload) => {
    try {
      commit(constants.POST_CONTACT_FORM);
      const response = await axios.post(`contact-us`, payload);
      commit(constants.POST_CONTACT_FORM_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_CONTACT_FORM_ERROR, error);
      return error.response;
    }
  },
};

const mutations = {
  [constants.GET_PUBLIC_VEHICLES]: (state) => {
    state.vehicles.status = "fetching";
    state.vehicles.error = null;
  },
  [constants.GET_PUBLIC_VEHICLES_SUCCESS]: (state, data) => {
    state.vehicles.status = "success";
    state.vehicles.data = data;
    state.vehicles.error = null;
  },
  [constants.GET_PUBLIC_VEHICLES_ERROR]: (state, error) => {
    state.vehicles.status = "error";
    state.vehicles.error = error;
  },

  [constants.SET_PUBLIC_VEHICLES_FILTERS]: (state, filters) => {
    if (filters.reset) {
      state.vehicles.filters = {
        resourcesPerPage: 6,
        page: 1,
      };
    }
    if (filters.fields) {
      state.vehicles.filters = {
        ...state.vehicles.filters,
        ...filters.fields,
      };
    }
  },
  [constants.POST_CONTACT_FORM]: (state) => {
    state.contactForm.status = "fetching";
    state.contactForm.error = null;
  },
  [constants.POST_CONTACT_FORM_SUCCESS]: (state, data) => {
    state.contactForm.status = "success";
    state.contactForm.data = data;
    state.contactForm.error = null;
  },
  [constants.POST_CONTACT_FORM_ERROR]: (state, error) => {
    state.contactForm.status = "error";
    state.contactForm.error = error;
  },
};

export default {
  state: getDefaultState(),
  actions,
  mutations,
};
