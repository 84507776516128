<template>
  <div v-if="!isExpired" class="text-muted">
    <small>Offer will expire in</small>
    {{ timeLeft }}
  </div>
  <div v-else>
    <small class="text-muted">Offer expired</small>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: ["serverTime", "expirationTime", "isExpired"],
  data() {
    return {
      intervalId: null,
      timeLeft: null,
    };
  },
  methods: {
    formatleftTime(hr, min, sec) {
      hr = hr < 10 ? `0${hr}` : hr;
      min = min < 10 ? `0${min}` : min;
      sec = sec < 10 ? `0${sec}` : sec;
      return `${hr}:${min}:${sec}`;
    },
    countdown() {
      clearInterval(this.intervalId);
      let diffTime = this.expirationTime - this.serverTime;
      let duration = moment.duration(diffTime * 1000, "milliseconds");
      const interval = 1000;

      this.intervalId = setInterval(() => {
        duration = moment.duration(duration - interval, "milliseconds");
        this.timeLeft = this.formatleftTime(
          duration.hours(),
          duration.minutes(),
          duration.seconds()
        );
      }, interval);
    },
  },
  beforeMount() {
    this.countdown();
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
};
</script>

<style scoped>
div {
  font-size: 1.5rem;
  margin-top: -2%;
}
@media (max-width: 600px) {
  div {
    font-size: 1rem;
  }
}
</style>
