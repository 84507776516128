import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    notifications: {
      status: null,
      data: [],
      error: null,
      filters: {
        offerTo: null,
        new: null,
        counter: null,
        resourcesPerPage: 6,
        page: 1,
      },
    },
    updateNotifications: {
      status: null,
      data: null,
      error: null,
    },
  };
}

const actions = {
  [constants.GET_NOTIFICATIONS]: async ({ commit, state }) => {
    try {
      commit(constants.GET_NOTIFICATIONS);
      const payload = Object.entries(state.notifications.filters).reduce(
        (a, [k, v]) => (v === null || v === "" ? a : { ...a, [k]: v }),
        {}
      );
      const response = await axios.get(`vehicle/offer-notifications`, {
        params: payload,
      });
      commit(constants.GET_NOTIFICATIONS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_NOTIFICATIONS_ERROR, error);
      throw error.response;
    }
  },
  [constants.PUT_NOTIFICATIONS]: async ({ commit }, data) => {
    try {
      commit(constants.PUT_NOTIFICATIONS);
      const response = await axios.put(`vehicle/offer-notifications`, data);
      commit(constants.PUT_NOTIFICATIONS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.PUT_NOTIFICATIONS_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.GET_NOTIFICATIONS]: (state) => {
    state.notifications.status = "fetching";
    state.notifications.error = null;
  },
  [constants.GET_NOTIFICATIONS_SUCCESS]: (state, data) => {
    state.notifications.status = "success";
    state.notifications.error = null;
    state.notifications.data = data;
  },
  [constants.GET_NOTIFICATIONS_ERROR]: (state, error) => {
    state.notifications.status = "error";
    state.notifications.error = error;
  },
  [constants.SET_NOTIFICATIONS_FILTERS]: (state, filters) => {
    if (filters.reset) {
      state.notifications.filters = {
        offerTo: null,
        new: null,
        counter: null,
        resourcesPerPage: 6,
        page: 1,
      };
    }
    if (filters.fields) {
      state.notifications.filters = {
        ...state.notifications.filters,
        ...filters.fields,
      };
    }
  },
  [constants.PUT_NOTIFICATIONS]: (state) => {
    state.updateNotifications.status = "fetching";
    state.updateNotifications.error = null;
  },
  [constants.PUT_NOTIFICATIONS_SUCCESS]: (state, data) => {
    state.updateNotifications.status = "success";
    state.updateNotifications.data = data;
    state.updateNotifications.error = null;
  },
  [constants.PUT_NOTIFICATIONS_ERROR]: (state, error) => {
    state.updateNotifications.status = "error";
    state.updateNotifications.error = error;
  },
};

export default {
  state: getDefaultState(),
  actions,
  mutations,
};
