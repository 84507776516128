<template>
  <b-modal
    id="select-vehicle-table-fields"
    title="Select columns to show"
    @show="setData()"
  >
    <b-form-checkbox-group v-model="selected">
      <b-row>
        <b-col>
          <b-form-checkbox value="checks">Checks</b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox value="conditionalReports">CR</b-form-checkbox>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <b-form-checkbox value="notes">Notes</b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox value="recall">Recall</b-form-checkbox>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <b-form-checkbox value="carfax">Carfax</b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox value="exportLogistic">
            Export Logistic
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <b-form-checkbox value="exportPaperwork">
            Export Paperwork
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox value="registry">Registry</b-form-checkbox>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <b-form-checkbox value="sold">Sold</b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox value="visualInspection">
            Visual Inspection
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-form-checkbox-group>

    <template v-slot:modal-footer="{ ok }">
      <b-button :disabled="isLoading" variant="success" @click="save(ok)">
        Save
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { defaultFields, optionalFields } from "../vehicleTableFields";
export default {
  props: ["fieldsFromTable"],
  data() {
    return {
      selected: [],
      isLoading: false,
    };
  },
  methods: {
    setData() {
      this.selected = [];
      this.fieldsFromTable.filter((field) => {
        this.selected.push(field.key);
      });
    },
    getFieldsFromCookie() {
      let array = [];
      this.selected.forEach((val) => {
        let found = optionalFields.find((field) => field.key === val);
        if (found) array.push(found);
      });
      return array;
    },
    save(callback) {
      const optionalFields = this.getFieldsFromCookie();
      this.$cookies.set(
        "fields",
        JSON.stringify([...defaultFields, ...optionalFields])
      );
      this.$emit("getCookie");
      callback();
    },
  },
};
</script>

<style></style>
