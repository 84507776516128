<template>
  <b-card>
    <b-row>
      <b-col cols="10">
        <small class="text-muted">
          {{ note.date | formatDateTime }} - {{ note.user.lastName }}
          {{ note.user.firstName }}
        </small>
      </b-col>
      <b-col>
        <div class="text-right">
          <span
            class="dot"
            :class="[note.status ? 'dot-success' : 'dot-danger']"
          />
        </div>
      </b-col>
    </b-row>
    <p class="text-justify mt-3">
      {{ note.description }}
    </p>
  </b-card>
</template>

<script>
export default {
  props: ["note"],
};
</script>

<style></style>
