<template>
  <div>
    <b-navbar
      toggleable="lg"
      variant="light"
      align="end"
      class="nav mt-4 mb-5 shadow"
    >
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav align="end">
        <b-navbar-nav class="ml-auto">
          <b-navbar-nav>
            <b-nav-item
              :to="{ name: 'admin.users.registry' }"
              :active="$route.name === 'admin.users.registry'"
            >
              Pending
            </b-nav-item>
            <b-nav-item
              :to="{ name: 'admin.users.validate' }"
              :active="$route.name === 'admin.users.validate'"
            >
              Active
            </b-nav-item>
            <b-nav-item
              :to="{ name: 'admin.users.rejected' }"
              :active="$route.name === 'admin.users.rejected'"
            >
              Rejected
            </b-nav-item>
          </b-navbar-nav>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <router-view></router-view>
  </div>
</template>
<script>
export default {};
</script>
