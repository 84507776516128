<template>
  <b-tooltip :target="'tooltip-google-maps-' + id" triggers="hover">
    <h5 class="mt-2">{{ location.name }}</h5>
    <br />
    <h5>Address</h5>
    {{ location.address }}
    <br />
    <br />
    <a
      :href="location.link"
      target="_blank"
      class="mt-2"
      style="color: white; text-decoration: none"
    >
      View on Google Maps
    </a>
  </b-tooltip>
</template>

<script>
export default {
  props: ["id", "location"],
};
</script>
