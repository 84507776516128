import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    buyNow: {
      status: null,
      data: null,
      error: null,
    },
    makeAnOffer: {
      status: null,
      data: null,
      error: null,
    },
    vehicleDetailsSideBar: {
      data: null,
      isShowing: false,
    },
    vinInformationSideBar: {
      data: null,
      isShowing: false,
    },
  };
}

const actions = {
  [constants.POST_VEHICLE_BUY_NOW]: async ({ commit }, data) => {
    try {
      commit(constants.POST_VEHICLE_BUY_NOW);
      const response = await axios.post(`vehicle/buy`, data);
      commit(constants.POST_VEHICLE_BUY_NOW_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_VEHICLE_BUY_NOW_ERROR, error);
      throw error.response;
    }
  },

  [constants.POST_VEHICLE_MAKE_AN_OFFER]: async ({ commit }, data) => {
    try {
      commit(constants.POST_VEHICLE_MAKE_AN_OFFER);
      const response = await axios.post(`vehicle/offer`, data);
      commit(constants.POST_VEHICLE_MAKE_AN_OFFER_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_VEHICLE_MAKE_AN_OFFER_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.POST_VEHICLE_BUY_NOW]: (state) => {
    state.buyNow.status = "fetching";
    state.buyNow.error = null;
  },
  [constants.POST_VEHICLE_BUY_NOW_SUCCESS]: (state, data) => {
    state.buyNow.status = "success";
    state.buyNow.data = data;
    state.buyNow.error = null;
  },
  [constants.POST_VEHICLE_BUY_NOW_ERROR]: (state, error) => {
    state.buyNow.status = "error";
    state.buyNow.error = error;
  },
  [constants.POST_VEHICLE_MAKE_AN_OFFER]: (state) => {
    state.makeAnOffer.status = "fetching";
    state.makeAnOffer.error = null;
  },
  [constants.POST_VEHICLE_MAKE_AN_OFFER_SUCCESS]: (state, data) => {
    state.makeAnOffer.status = "success";
    state.makeAnOffer.data = data;
    state.makeAnOffer.error = null;
  },
  [constants.POST_VEHICLE_MAKE_AN_OFFER_ERROR]: (state, error) => {
    state.makeAnOffer.status = "error";
    state.makeAnOffer.error = error;
  },
  [constants.SET_VEHICLE_DETAILS_SIDEBAR]: (state, vehicle) => {
    state.vehicleDetailsSideBar.data = vehicle;
  },
  [constants.SET_IS_SHOWING_VEHICLE_DETAILS_SIDEBAR]: (state, status) => {
    state.vehicleDetailsSideBar.isShowing = status;
  },
  [constants.SET_IS_SHOWING_VIN_INFORMATION_SIDEBAR]: (state, data) => {
    state.vinInformationSideBar.isShowing = data.status;
    state.vinInformationSideBar.data = data.data;
  }
};

export default {
  state: getDefaultState(),
  actions,
  mutations,
};
