<template>
  <b-modal
    hide-footer
    @show="show"
    size="lg"
    centered
    id="login-modal"
    ref="modal"
  >
    <LoginForm />
  </b-modal>
</template>

<script>
import LoginForm from "./LoginForm.vue";
export default {
  components: { LoginForm },
  methods: {
    show() {},
  },
};
</script>

<style></style>
