import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    vehicle: {
      status: null,
      data: null,
      error: null,
      filters: {
        vinNumber: null,
        location: null,
        search: null,
        make: null,
        model: null,
        trim: null,
        transmissionType: null,
        releaseDate1: null,
        releaseDate2: null,
        year1: null,
        year2: null,
        mile1: null,
        mile2: null,
        price1: null,
        price2: null,
        status: null,
        trackingStatus: null,
        mainOfferId: null,
        new: null,
      },
    },
  };
}

const actions = {
  [constants.GET_VEHICLE]: async ({ commit, state }) => {
    try {
      commit(constants.GET_VEHICLE);
      const payload = Object.entries(state.vehicle.filters).reduce(
        (a, [k, v]) => (v === null || v === "" ? a : { ...a, [k]: v }),
        {}
      );
      const response = await axios.get(`vehicle/findVehicle`, {
        params: payload,
      });
      commit(constants.GET_VEHICLE_SUCCESS, response.data);
      return response;
    } catch (error) {
      commit(constants.GET_VEHICLE_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.GET_VEHICLE]: (state) => {
    state.vehicle.status = "fetching";
    state.vehicle.error = null;
  },
  [constants.GET_VEHICLE_SUCCESS]: (state, data) => {
    state.vehicle.status = "success";
    state.vehicle.error = null;
    state.vehicle.data = data;
  },
  [constants.GET_VEHICLE_ERROR]: (state, error) => {
    state.vehicle.status = "error";
    state.vehicle.error = error;
  },

  [constants.SET_ONE_VEHICLE_FILTERS]: (state, filters) => {
    if (filters.reset) {
      state.vehicle.filters = {
        vinNumber: null,
        location: null,
        search: null,
        make: null,
        model: null,
        trim: null,
        transmissionType: null,
        releaseDate1: null,
        releaseDate2: null,
        year1: null,
        year2: null,
        mile1: null,
        mile2: null,
        price1: null,
        price2: null,
        status: null,
        trackingStatus: null,
        mainOfferId: null,
        new: null,
      };
    }
    if (filters.fields) {
      state.vehicle.filters = {
        ...state.vehicle.filters,
        ...filters.fields,
      };
    }
  },
};

export default {
  state: getDefaultState(),
  actions,
  mutations,
};
