import { mapState } from "vuex";
import {
  CLEAR_VEHICLES_OFFERS,
  GET_VEHICLES_WITH_OFFERS,
  SHOW_OFFERS_SIDEBAR,
} from "./actions";

const vehicleOffersMixins = {
  computed: {
    ...mapState({
      offersStatus: (state) =>
        state.adminStore.vehicles.offersStore.offers.status,
      offers: (state) => state.adminStore.vehicles.offersStore.offers,
    }),
  },
  methods: {
    clearOffers() {
      this.$store.commit(CLEAR_VEHICLES_OFFERS);
      this.$store.commit(SHOW_OFFERS_SIDEBAR, false);
    },
    getOffers(withFilters) {
      this.$store.dispatch(GET_VEHICLES_WITH_OFFERS, withFilters);
    },
  },
};

export { vehicleOffersMixins };
