import listStore from "./list/store";
import carRegistry from "./registry/store";
import offersStore from "./offers/store";

export default {
  state: {},
  actions: {},
  mutations: {},
  modules: {
    listStore,
    carRegistry,
    offersStore,
  },
};
