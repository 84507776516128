<template>
  <div>
    <!--    <Lingallery
      v-if="images.length > 0"
      :addons="{ enableLargeView: true }"
      :iid.sync="currentId"
      :width="width"
      :height="height"
      :items="images"
      class="close-button"
      :showThumbnails="showThumbnail"
    /> -->

    <Photoswipe
      :options="optionsPhotos"
      class="w-60"
      :style="typeList == 'list' ? 'width: 20rem !important' : ''"
    >
      <div
        class="scroll d-flex overflow-hide"
        :class="typeList == 'list' ? 'height-list' : 'height-grid'"
      >
        <img
          v-for="(image, index) in images"
          :src="image.src"
          v-pswp="image.src"
          class="img-thumbnail w-100 p-0"
          :class="typeList == 'list' ? 'one-image' : ''"
          :key="`auto-exterior-${index}`"
        />
      </div>
    </Photoswipe>
  </div>
</template>

<script>
/* import Lingallery from "lingallery";
 */
export default {
  name: "VehicleCardGallery",
  props: {
    images: {
      type: Array,
      required: true,
      default: () => [],
    },
    showThumbnail: {
      type: Boolean,
      required: false,
      default: true,
    },
    typeList: {
      type: String,
      required: false,
      default: "grid",
    },
  },
  components: {
    /* Lingallery */
  },

  data() {
    return {
      currentId: 0,
      mainImage: null,
      index: null,
      optionsPhotos: {
        share: false,
      },
    };
  },

  mounted() {
    this.mainImage = this.images[0];
  },
  methods: {
    selectImage(index, src) {
      if (!src) return;
      let imgNotFound = this.imgsNotFound.filter((i) => i === index);
      this.$emit("onSelectImage", {
        index,
        src,
        tab: this.tab,
        imgNotFound: imgNotFound.length ? true : false,
      });
    },
    errorLoadImg(index) {
      this.imgsNotFound.push(index);
    },
    nextImg() {
      this.index++;
      this.mainImage = this.images[this.index];
    },
    backImg() {
      this.index--;
      this.mainImage = this.images[this.index];
    },
  },
};
</script>

<style>
#largeViewContainer {
  position: relative;
  display: inline-block;
}
#largeViewContainer a {
  border-radius: 0 !important;
  background-color: white !important;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1001;
}
@media screen and (min-width: 900px) {
  #largeViewContainer img {
    width: auto !important;
    height: 100% !important;
  }
}
/* @media screen and (min-width: 900px) {
  #largeViewContainer a {

    position: fixed;
    top: 5% !important;
    right: 16% !important;
    display: block;
    width: 32px;
    height: 32px;
    opacity: 0.5;
    cursor: pointer;
    z-index: 1001 !important;
  }
} */

/* a::after {
  background-color: red !important;
}
a::before {
  background-color: red !important;
} */

.img-damage {
  max-height: 100%;
  width: auto;
  display: inline-block;
  float: none;
}
.scroll {
  overflow-x: auto;
  white-space: nowrap;
}
img {
  cursor: pointer;
}
.height-list {
  height: 12rem !important;
  max-width: 75% !important;
}
@media screen and (max-width: 767px) {
  .height-4rem {
    height: 10rem !important;
  }
}
.height-grid {
  height: 20rem !important;
}
@media screen and (max-width: 767px) {
  .height-4rem {
    height: 10rem !important;
  }
}

.pswp__button--share {
  display: none !important;
}
.one-image {
  height: 100% !important;
}
</style>
