import VehiclePage from "@/modules/vehicle/vehiclePage.vue";
const routes = [
  {
    path: "/vehicle/:vin",
    name: "vehicle",
    component: VehiclePage,
  },
];

export default routes;
