/** @format */

export const POST_USER = "POST_USER";
export const POST_USER_SUCCESS = "POST_USER_SUCCESS";
export const POST_USER_ERROR = "POST_USER_ERROR";

export const GET_USER_CLIENT = " GET_USER_CLIENT";
export const GET_USER_CLIENT_SUCCESS = "GET_USER_CLIENT_SUCCESS";
export const GET_USER_CLIENT_ERROR = "GET_USER_CLIENT_ERROR";

export const POST_USER_COMPANY = "POST_POST_USER_COMPANY";
export const POST_USER_COMPANY_SUCCESS = "POST_USER_COMPANY_SUCCESS";
export const POST_USER_COMPANY_ERROR = "POST_USER_COMPANY_ERROR";

export const POST_EMAIL_VALIDATION = "POST_EMAIL_VALIDATION";
export const POST_EMAIL_VALIDATION_SUCCESS = "POST_EMAIL_VALIDATION_SUCCESS";
export const POST_EMAIL_VALIDATION_ERROR = "POST_EMAIL_VALIDATION_ERROR";
