<template>
  <b-sidebar
    lazy
    :width="widthSidebar"
    :visible="vehicleDetailsSideBar.isShowing"
    id="sidebar-vehicle-details"
    shadow
    right
  >
    <b-overlay>
      <VehicleCard
        :vehicle="vehicleDetailsSideBar.data"
        :isShowFullInfo="true"
      />
    </b-overlay>
  </b-sidebar>
</template>

<script>
import VehicleCard from "../VehicleCard";
export default {
  name: "VehicleDetailsSideBar",
  components: {
    VehicleCard,
  },
  data() {
    return {
      showing: false,
    };
  },
  computed: {
    widthSidebar() {
      return this.$vssWidth > 1280
        ? "25%"
        : this.$vssWidth <= 576
        ? "100%"
        : "50%";
    },
    vehicleDetailsSideBar() {
      return this.$store.state.adminStore.vehicles.listStore.componentsStore
        .vehicleDetailsSideBar;
    },
    vehicleModel() {
      return this.vehicleDetailsSideBar.data
        ? this.vehicleDetailsSideBar.data.vinNumber.model
        : "";
    },
  },
};
</script>

<style></style>
