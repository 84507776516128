export const POST_VEHICLE_CHECK = "POST_VEHICLE_CHECK";
export const POST_VEHICLE_CHECK_SUCCESS = "POST_VEHICLE_CHECK_SUCCESS";
export const POST_VEHICLE_CHECK_ERROR = "POST_VEHICLE_CHECK_ERROR";

export const SET_VEHICLES_CHECKS_FILTERS = "SET_VEHICLES_CHECKS_FILTERS";

export const GET_VEHICLE_CHECKS = "GET_VEHICLE_CHECKS";
export const GET_VEHICLE_CHECKS_SUCCESS = "GET_VEHICLE_CHECKS_SUCCESS";
export const GET_VEHICLE_CHECKS_ERROR = "GET_VEHICLE_CHECKS_ERROR";

export const POST_VEHICLE_CONDITIONAL_REPORT =
  "POST_VEHICLE_CONDITIONAL_REPORT";
export const POST_VEHICLE_CONDITIONAL_REPORT_SUCCESS =
  "POST_VEHICLE_CONDITIONAL_REPORT_SUCCESS";
export const POST_VEHICLE_CONDITIONAL_REPORT_ERROR =
  "POST_VEHICLE_CONDITIONAL_REPORT_ERROR";

export const SET_VEHICLE_CONDITIONAL_REPORTS_FILTERS =
  "SET_VEHICLE_CONDITIONAL_REPORTS_FILTERS";

export const GET_VEHICLE_CONDITIONAL_REPORTS =
  "GET_VEHICLE_CONDITIONAL_REPORTS";
export const GET_VEHICLE_CONDITIONAL_REPORTS_SUCCESS =
  "GET_VEHICLE_CONDITIONAL_REPORTS_SUCCESS";
export const GET_VEHICLE_CONDITIONAL_REPORTS_ERROR =
  "GET_VEHICLE_CONDITIONAL_REPORTS_ERROR";

export const POST_VEHICLE_NOTE = "POST_VEHICLE_NOTE";
export const POST_VEHICLE_NOTE_SUCCESS = "POST_VEHICLE_NOTE_SUCCESS";
export const POST_VEHICLE_NOTE_ERROR = "POST_VEHICLE_NOTE_ERROR";

export const SET_VEHICLE_NOTES_FILTERS = "SET_VEHICLE_NOTES_FILTERS";

export const GET_VEHICLE_NOTES = "GET_VEHICLE_NOTES";
export const GET_VEHICLE_NOTES_SUCCESS = "GET_VEHICLE_NOTES_SUCCESS";
export const GET_VEHICLE_NOTES_ERROR = "GET_VEHICLE_NOTES_ERROR";
