<template>
  <div>
    <div class="container-fluid">
      <ProgressBar :step="2" />
      <br />
      <VinData />
    </div>
    <br />
  </div>
</template>

<script>
import ProgressBar from "./ProgressBar";
import VinData from "./VinData";
import { generalMixin } from "@/modules/mixin";

export default {
  components: { ProgressBar, VinData },
  mixins: [generalMixin],
  data() {
    return {
      colorOptions: [
        { value: null, text: "Color", disabled: true },
        { value: "Black", text: "Black" },
        { value: "White", text: "White" },
        { value: "Red", text: "Red" },
        { value: "Orange", text: "Orange" },
        { value: "Pink", text: "Pink" },
        { value: "Brown", text: "Brown" },
        { value: "Yellow", text: "Yellow" },
        { value: "Blue", text: "Blue" },
      ],
      tires: [],
      typeOptions: [
        { value: "Manual", text: "Manual" },
        { value: "Automatic", text: "Automatic" },
      ],
      allCheckboxes: [
        {
          text: "Air conditioning",
          name: "airConditioning",
          isChecked: false,
        },
        { text: "Memory seats", name: "memorySeats", isChecked: true },
        { text: "Alarm", name: "alarm", isChecked: false },
        {
          text: "Navigation system",
          name: "navigationSystem",
          isChecked: false,
        },
        { text: "Alloys wheels", name: "alloyWheels", isChecked: false },
        { text: "Power locks", name: "powerLocks", isChecked: false },
        { text: "Bluetooth", name: "bluetooth", isChecked: false },
        {
          text: "Power mirrors",
          name: "powerMirrors",
          isChecked: false,
        },
        {
          text: "Dual climate control",
          name: "dualClimateControl",
          isChecked: false,
        },
        { text: "Power seats", name: "powerSeats", isChecked: false },
        {
          text: "Entertainment package",
          name: "entertainmentPackage",
          isChecked: false,
        },
        {
          text: "Power windows",
          name: "powerWindows",
          isChecked: false,
        },
        { text: "Fog lights", name: "fogLights", isChecked: false },
        {
          text: "Stability control",
          name: "stabilityControl",
          isChecked: false,
        },
        {
          text: "Heated mirrors",
          name: "heatedMirrors",
          isChecked: false,
        },
        { text: "Sunroof", name: "sunroof", isChecked: false },
        { text: "Heated seats", name: "heatedSeats", isChecked: false },
        { text: "Tow package", name: "towPackage", isChecked: false },
        {
          text: "Keyles entry",
          name: "keylessEntry",
          isChecked: false,
        },
        {
          text: "Xenon headlights",
          name: "xenonHeadlights",
          isChecked: false,
        },
        {
          text: "Leather seats",
          name: "leatherSeats",
          isChecked: false,
        },
      ],
      isLoading: false,
    };
  },
  methods: {
    nextPage() {
      this.$router.push({
        name: "admin.vehicles.registry.uploaded",
        params: { vin: this.$route.params.vin },
      });
    },
    payload() {
      const data = {
        vinNumber: this.$route.params.vin,
      };
      return data;
    },
  },
};
</script>

<style scoped>
@media screen and (min-width: 1000px) {
  .main-cont {
    padding-left: 30%;
    padding-right: 30%;
  }
}
</style>
