import Vue from "vue";
import VueRouter from "vue-router";
import SuperAdminRoutes from "@/modules/superAdmin/routes";
import HomeRoutes from "@/modules/home/routes";
import BuyerRoutes from "@/modules/buyer/routes";
import AuthRoutes from "@/modules/auth/routes";
import AdminRoutes from "@/modules/admin/routes";
import AgentRoutes from "@/modules/agent/routes";
import UnAuthenticatedRoutes from "@/modules/unAuthenticatedUser/routes";
import VehicleRoutes from "@/modules/vehicle/routes";
Vue.use(VueRouter);

const routes = [
  ...AuthRoutes,
  ...HomeRoutes,
  ...SuperAdminRoutes,
  ...AdminRoutes,
  ...BuyerRoutes,
  ...AgentRoutes,
  ...UnAuthenticatedRoutes,
  ...VehicleRoutes,
];

VueRouter.prototype.open = function (routeObject) {
  const { href } = this.resolve(routeObject);
  window.open(href, "_blank");
};

const router = new VueRouter({
  routes,
});

export default router;
