import List from "../admin/vehicles/list/List.vue";
import Base from "./Base.vue";

const routes = [
  {
    path: "/inventory",
    name: "vehicleInventory",
    component: Base,
    children: [
      {
        path: "vehicles",
        name: "inventory.vehicles",
        component: List,
      },
    ],
  },
];

export default routes;
