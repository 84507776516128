<template>
  <b-row>
    <b-col class="mb-3" lg="3" md="3" sm="12">
      <Search
        ref="searchVehiclesComponent"
        class="sticky-top"
        :unAuthUser="true"
        style="z-index: 999 !important"
      />
    </b-col>
    <b-col xl="9" md="9" sm="12">
      <b-row>
        <b-col v-if="vehiclesStatus === 'fetching'" class="mt-2">
          <b-row>
            <b-col
              v-for="(data, index) in [1, 2, 3, 4, 5, 6]"
              :key="index"
              lg="4"
              md="6"
              sm="12"
            >
              <b-card no-body img-top class="mb-3">
                <b-skeleton-img card-img="top" aspect="3:1" />
                <b-card-body>
                  <b-skeleton-table
                    :rows="5"
                    :columns="1"
                    :table-props="{ bordered: true, striped: true }"
                  />
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          v-else-if="publicVehicles.data && !publicVehicles.data.length"
          class="mt-2 col-lg-4 col-centered"
        >
          <b>No records to show</b>
        </b-col>
        <template v-else>
          <b-col cols="12">
            <b-tabs v-model="listType" content-class="mt-3" class="mbn-3">
              <b-tab title="First">
                <template #title>
                  <b-icon icon="grid"></b-icon>
                </template>
                <b-row>
                  <b-col
                    class="mt-2"
                    xl="4"
                    md="4"
                    sm="12"
                    v-for="vehicle in publicVehicles.data"
                    :key="vehicle.id"
                  >
                    <!--           <VehicleCard :vehicle="vehicle" :showCRModal="true" />
 -->
                    <VehicleCard :vehicle="vehicle" :typeList="'grid'" />
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab title="">
                <template #title>
                  <b-icon icon="list"></b-icon>
                </template>
                <b-col cols="12">
                  <VehiclesListView
                    :vehicles="publicVehicles.data"
                    :typeList="'list'"
                  />
                </b-col>
              </b-tab>
            </b-tabs>
          </b-col>
        </template>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { mapState } from "vuex";
import Search from "./Search";
/* import VehicleCard from "./VehicleCard";
 */
import VehicleCard from "./VehicleCard/VehicleCard.vue";
import { listVehiclesMixin } from "./mixins";
import { SET_VEHICLE_FILTER_ACTION } from "./actions";
import VehiclesListView from "./VehiclesListView.vue";

export default {
  components: { Search, VehicleCard, VehiclesListView },
  mixins: [listVehiclesMixin],
  computed: {
    ...mapState({
      vehiclesStatus: (state) => state.home.vehicles.status,
    }),
  },
  data() {
    return {
      listType: 1,
    };
  },
  mounted() {
    this.$store.commit(SET_VEHICLE_FILTER_ACTION, "PUBLIC_GET_VEHICLES");
  },
};
</script>

<style scoped>
.sticky-top {
  z-index: 1 !important;
}
</style>
