var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"mt-0",attrs:{"fluid":""}},[_c('div',{staticClass:"table-responsive"}),_c('b-table',{attrs:{"responsive":"","thead-class":"text-center bg-light-grey ","head-variant":"light","bordered":"","small":"","show-empty":"","outlined":"","items":_vm.vehicles,"fields":_vm.fields,"stacked":"sm"},scopedSlots:_vm._u([{key:"cell(image)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"mx-auto component-wrapper"},[(
             item.assetSubstatusCode == 'ÉCHANGE À LEASE' ||
             item.assetSubstatusCode == 'ÉCHANGE À WHO' ||
             item.assetSubstatusCode == 'ÉCHANGE WHO RUSH' ||
             item.assetSubstatusCode == 'ÉCHANGE A WHO US'
           )?_c('div',{staticClass:"coming-soon-tag"},[_vm._v(" Coming soon ")]):_vm._e(),(item.websiteAssetSaleStatus == 'NEW')?_c('div',{staticClass:"flame-new-bottom"},[_vm._v(" 🔥 ")]):_vm._e(),_c('VehiclesListViewImage',{attrs:{"vehicle":item}}),_c('div',{staticClass:"text-center bg-primary"},[_c('strong',[_c('i',{staticClass:"text-white"},[_vm._v(" "+_vm._s(item.condition)+" ")])])])],1)]}},{key:"cell(vinNumber)",fn:function(ref){
           var item = ref.item;
return [(item.vinNumber)?_c('router-link',{attrs:{"to":_vm.getVehiclePageUrl(item)}},[_c('i',[_vm._v(" "+_vm._s(item.vinNumber)+" ")])]):_vm._e(),(item.quoteFile)?_c('a',{attrs:{"href":item.quoteFile,"target":"_blank"}},[_c('b-icon',{staticClass:"cursor-pointer",attrs:{"icon":"file-earmark"}})],1):_vm._e()]}},{key:"cell(year)",fn:function(ref){
           var item = ref.item;
return [_vm._v(" "+_vm._s(item.year)+" ")]}},{key:"cell(make)",fn:function(ref){
           var item = ref.item;
return [_vm._v(" "+_vm._s(item.make)+" ")]}},{key:"cell(model)",fn:function(ref){
           var item = ref.item;
return [_vm._v(" "+_vm._s(item.model)+" ")]}},{key:"cell(miles)",fn:function(ref){
           var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.miles))+" Kms ")]}},{key:"cell(cr)",fn:function(ref){
           var item = ref.item;
return [(item.vehicleHasConditionalReports.length > 0)?_c('CRButton',{staticClass:"mx-auto",attrs:{"vehicle":item}}):_vm._e()]}},{key:"cell(actions)",fn:function(ref){
           var item = ref.item;
return [(item && item.priceRetail)?_c('div',{staticClass:"mb-2 pt-2 text-center"},[_c('strong',[_vm._v(" "+_vm._s(_vm._f("currency")(item.priceRetail))+" ")])]):_vm._e(),(item && item.priceWholesale && !_vm.userIsAdmin && _vm.userIsWholesale)?_c('div',{staticClass:"mb-2 pt-2 text-center"},[_c('strong',[_vm._v(" "+_vm._s(_vm._f("currency")(item.priceWholesale))+" ")])]):_vm._e(),(_vm.vehicleAcceptOffer(item) || item.status === 'Sold')?_c('div',{staticClass:"m"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/img/TOOLATE.png"),"alt":"sold-image"}})]):_c('div',[(_vm.viewOffers)?_c('b-row',{},[_c('b-col',{staticClass:"mb-1"},[_c('b-button',{staticClass:"shadow",attrs:{"variant":"primary","block":""},on:{"click":function($event){return _vm.showVehicleOffers(item)}}},[_vm._v(" "+_vm._s(_vm.$t("viewOffers"))+" ")])],1)],1):(!_vm.isHome && _vm.user)?_c('b-row',{},[_c('b-col',[(item.status !== 'Inactive' && _vm.userIsAdmin)?_c('b-button',{attrs:{"variant":"primary","block":""},on:{"click":function($event){return _vm.editVehicle(item.vinNumber)}}},[_c('b-icon',{attrs:{"icon":"pencil"}})],1):_vm._e()],1)],1):_vm._e(),(
             !_vm.isHome &&
             !_vm.isActiveOffer &&
             !_vm.isExpiredOffer &&
             (_vm.userHasOffer || item.lastOffer) &&
             _vm.userHasCompany
           )?_c('b-row',{},[_c('b-col',[_c('b-button',{attrs:{"variant":"primary","block":"","size":"sm"},on:{"click":function($event){return _vm.changeOfferTab(item.id)}}},[_c('h5',{},[_vm._v(" "+_vm._s(_vm.$t("currentOffer"))+": "),(item.lastOffer)?_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.lastOffer.offerPrice))+" ")]):(_vm.userHasOffer)?_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.currentOffer))+" ")]):_vm._e()])])],1)],1):_vm._e()],1),(
           !_vm.isHome &&
           !_vm.userHasOffer &&
           !item.lastOffer &&
           !_vm.isExpiredOffer &&
           !_vm.isActiveOffer &&
           !_vm.userIsAdmin &&
           !_vm.userIsAgent &&
           !_vm.userIsRetail
         )?_c('MakeAnOffer',{attrs:{"vehicleId":item.id,"price":item.priceRetail,"userPending":!_vm.userHasCompany,"stacked":true,"vehicle":item},on:{"setUserHasOffer":function($event){return _vm.setUserHasOffer($event)},"refresh":function($event){return _vm.refreshVehicles()}}}):_vm._e(),_c('b-button',{staticClass:"mt-2",attrs:{"variant":"primary","block":""},on:{"click":function($event){_vm.copyToClipboard(_vm.generateUrlToCopy(item))}}},[_vm._v(" "+_vm._s(_vm.$t("copyLink"))+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }