import Base from "@/modules/admin/Base.vue";
import List from "@/modules/admin/vehicles/list/List.vue";
import GeneralInformation from "@/modules/admin/vehicles/registry/GeneralInformation";
import SuccessCarUploaded from "@/modules/admin/vehicles/registry/SuccessCarUploaded";

//import RegistryVehicle from "@/modules/admin/vehicles/registry/SearchVehicleByVin.vue";

import { ifRoleAuthenticated } from "@/router/navigationGuards";
import OffersFullScreen from "@/modules/admin/vehicles/offers/OffersFullScreen";
import NotificationsList from "@/modules/admin/notifications/List.vue";

const routes = [
  {
    path: "/agent",
    name: "agent",
    component: Base,
    beforeEnter: (to, from, next) => {
      ifRoleAuthenticated(to, from, next, "ROLE_AGENT");
    },
    children: [
      {
        path: "vehicles",
        name: "agent.vehicles",
        component: Base,
        children: [
          {
            path: "list",
            name: "agent.vehicles.list",
            component: List,
          },
          {
            path: "registry/:vin",
            name: "agent.vehicles.edit.general",
            component: GeneralInformation,
          },
          {
            path: "offers",
            name: "agent.vehicles.offers",
            component: OffersFullScreen,
          },
          {
            path: "registry/:vin/uploaded",
            name: "agent.vehicles.registry.uploaded",
            component: SuccessCarUploaded,
          },
        ],
      },
      {
        path: "notifications",
        name: "agent.notifications",
        component: NotificationsList,
      },
    ],
  },
];

export default routes;
