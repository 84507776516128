import {
  SET_VEHICLE_INFO,
  GET_VEHICLES_INFO,
  SET_VEHICLES_INFO_FILTERS,
} from "./actions";
import {
  SET_VEHICLES_CHECKS_FILTERS,
  GET_VEHICLE_CHECKS,
  SET_VEHICLE_CONDITIONAL_REPORTS_FILTERS,
  GET_VEHICLE_CONDITIONAL_REPORTS,
  SET_VEHICLE_NOTES_FILTERS,
  GET_VEHICLE_NOTES,
} from "./components/actions";
const superAdminMixin = {
  data() {
    return {};
  },
  methods: {
    getVehiclesInfo() {
      this.$store.dispatch(GET_VEHICLES_INFO).then().catch();
    },
    goToDashBoard(vehicle) {
      this.$router.push({
        name: "superadmin.vehicles.dashboard",
      });
      this.$store.commit(SET_VEHICLE_INFO, vehicle);
    },
    changePage(page) {
      this.$store.commit(SET_VEHICLES_INFO_FILTERS, {
        reset: true,
        fields: { page },
      });
      this.getVehiclesInfo();
    },
    resetFilters() {
      this.$store.commit(SET_VEHICLES_INFO_FILTERS, { reset: true });
    },
    search(search) {
      this.$store.commit(SET_VEHICLES_INFO_FILTERS, { fields: { search } });
      this.getVehiclesInfo();
    },
    openChecksModal(vehicle) {
      this.$store.commit(SET_VEHICLE_INFO, vehicle);
      this.$bvModal.show("checks-modal");
    },
    openConditionalReportsModal(vehicle) {
      this.$store.commit(SET_VEHICLE_INFO, vehicle);
      this.$bvModal.show("conditional-reports-modal");
    },
    openNotesModal(vehicle) {
      this.$store.commit(SET_VEHICLE_INFO, vehicle);
      this.$bvModal.show("notes-modal");
    },
    getChecks(resetFilters, vehicle) {
      this.$store.commit(SET_VEHICLES_CHECKS_FILTERS, {
        reset: resetFilters,
        fields: { vehicle: vehicle.id },
      });
      this.$store
        .dispatch(GET_VEHICLE_CHECKS)
        .then(() => { })
        .catch(() => {
          this.toastMessage(
            "Vehicle checks could not obtained",
            "Warning",
            "warning"
          );
        });
    },
    getConditionalReports(resetFilters, vehicle) {
      this.$store.commit(SET_VEHICLE_CONDITIONAL_REPORTS_FILTERS, {
        reset: resetFilters,
        fields: { vehicle: vehicle.id },
      });
      this.$store
        .dispatch(GET_VEHICLE_CONDITIONAL_REPORTS)
        .then(() => { })
        .catch(() => {
          this.toastMessage(
            "Condition reports could not obtained",
            "Warning",
            "warning"
          );
        });
    },
    getNotes(resetFilters, vehicle) {
      this.$store.commit(SET_VEHICLE_NOTES_FILTERS, {
        reset: resetFilters,
        fields: { vehicle: vehicle.id },
      });
      this.$store
        .dispatch(GET_VEHICLE_NOTES)
        .then(() => { })
        .catch(() => {
          this.toastMessage("Notes could not obtained", "Warning", "warning");
        });
    },
  },
};

export { superAdminMixin };
