export const POST_DEALER = "POST_DEALER";
export const POST_DEALER_SUCCESS = "POST_DEALER_SUCCESS";
export const POST_DEALER_ERROR = "POST_DEALER_ERROR";

export const POST_VALIDATE_EMAIL = "POST_VALIDATE_EMAIL";
export const POST_VALIDATE_EMAIL_SUCCESS = "POST_VALIDATE_EMAIL_SUCCESS";
export const POST_VALIDATE_EMAIL_ERROR = "POST_VALIDATE_EMAIL_ERROR";

export const DELETE_USER = "DELETE_USER"
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const DELETE_USER_ERROR = "DELETE_USER_ERROR"

