import Base from "@/modules/auth/Base.vue";
import LoginForm from "./login/LoginForm.vue";
import ForgotPassword from "./forgotPassword/ForgotPassword.vue";
import RecoverPassword from "./forgotPassword/RecoverPassword.vue";

const routes = [
  {
    path: "/auth",
    name: "auth",
    component: Base,
    children: [
      {
        path: "login",
        name: "auth.login",
        component: LoginForm,
        meta: { layout: "auth" },
      },
      {
        path: "forgotPassword",
        name: "auth.forgotPassword",
        component: ForgotPassword,
      },
      {
        path: "password/:token",
        name: "auth.recoverPassword",
        component: RecoverPassword,
      },
    ],
  },
];

export default routes;
