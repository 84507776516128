<template>
  <div>
    <VehicleCardGallery
      v-if="!isLoadingImagesFromSpace"
      class=""
      :images="vehicleImages"
      :showThumbnail="false"
      :typeList="'list'"
      style="max-width: 15rem !important"
    />
    <div v-else>loading</div>
  </div>
</template>

<script>
import VehicleCardGallery from "./VehicleCard/VehicleCardGallery.vue";
import IMAGES_PARTS from "./VehicleCard/images-parts";
export default {
  name: "VehiclesListViewImage",
  components: {
    VehicleCardGallery,
  },
  props: {
    vehicle: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      vehicleImages: [],
      BASE_URL: "https://proleasing.nyc3.cdn.digitaloceanspaces.com/Prod",
      isLoadingImagesFromSpace: false,
    };
  },
  computed: {
    baseUrl() {
      return `${this.BASE_URL}/${this.vehicle?.year}/${this.vehicle?.make}/${this.vehicle?.model2}/${this.vehicle?.color}`;
    },
  },
  methods: {
    formatImagesFromSpace() {
      this.isLoadingImagesFromSpace = true;
      let images = [];
      let baseUrl = this.baseUrl;
      let promises = [];

      IMAGES_PARTS.forEach((part) => {
        let img = new Image();
        let promise = new Promise((resolve) => {
          const setImage = (extension) => {
            img.src = `${baseUrl}/${part}.${extension}`;
            img.onload = () => {
              images.push({
                id: `${baseUrl}/${part}.${extension}`,
                src: `${baseUrl}/${part}.${extension}`,
                largeViewSrc: `${baseUrl}/${part}.${extension}`,
                thumbnail: `${baseUrl}/${part}.${extension}`,
              });
              resolve();
            };
            img.onerror = () => {
              if (extension === "jpeg") {
                setImage("jpg"); // Intenta con .jpg si falla .jpeg
              } else {
                resolve();
              }
            };
          };

          setImage("jpeg"); // Comienza intentando cargar .jpeg
        });

        promises.push(promise);
      });

      Promise.all(promises).then(() => {
        if (images.length === 0) {
          images.push({
            id: "proleasing-logo.png",
            src: "proleasing-logo.png",
            largeViewSrc: "proleasing-logo.png",
            thumbnail: "proleasing-logo.png",
          });
        }

        if (images.find((img) => img.src !== "proleasing-logo.png")) {
          this.vehicleImages = images.filter(
            (img) => img.src !== "proleasing-logo.png"
          );
        } else {
          this.vehicleImages = images;
        }

        this.isLoadingImagesFromSpace = false;
      });
    },

    formatManualImages() {
      this.vehicleImages = this.vehicle.vehicleHasParts.map((part) => {
        return {
          id: part.id,
          src: part.photo,
          largeViewSrc: part.photo,
          thumbnail: part.photoThumbnail,
        };
      });
    },
  },
  mounted() {
    if (this.vehicle.vehicleHasParts.length > 0) {
      this.formatManualImages();
    } else {
      this.formatImagesFromSpace();
    }
  },
};
</script>

<style></style>
