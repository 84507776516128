<template>
  <b-card>
    <b-row>
      <b-col cols="10">
        <small class="text-muted mt-3">
          {{ check.registerDate | formatDateTime }} - {{ check.user.lastName }}
          {{ check.user.firstName }}
        </small>
      </b-col>
      <b-col class="text-right">
        <span
          class="dot mt-2"
          :class="[check.status ? 'dot-success' : 'dot-danger']"
        />
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <h5>{{ check.date | formatDate }}</h5>
      </b-col>
      <b-col class="text-right">
        <h5>#{{ check.number }}</h5>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col>
        <h2>
          <i>{{ check.amount | currency }}</i>
        </h2>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  props: ["check"],
};
</script>

<style></style>
