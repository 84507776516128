import axios from "axios";
import Config from "@/config";

const instance = axios.create({
  baseURL: Config.baseURL() + "/api/",
  headers: {
    "Access-Control-Allow-Origin": "*",
    contentType: "application/json",
  },
});

const errorResponseHandler = (error) => {
  if (error.response.status === 401) {
    localStorage.clear();
    // Redirect to home
    window.location.href = "/";
  }
  return Promise.reject({ ...error });
};

const successResponseHandler = (response) => {
  return response.data;
};

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access-token");
    const marketPlace = localStorage.getItem("marketPlace");
    if (token) config.headers.Authorization = `Bearer ${token}`;
    if (
      config.method &&
      ["post", "put", "delete", "get"].includes(config.method) &&
      marketPlace
    ) {
      config.headers["marketPlaceId"] = marketPlace;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => successResponseHandler(response),
  (error) => errorResponseHandler(error)
);

export default instance;
