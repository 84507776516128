<template>
  <b-row :class="{ 'mt-3': isBuyerView }">
    <b-col class="mb-3" xl="3" md="3">
      <Search
        ref="searchVehiclesComponent"
        class="sticky-top"
        style="z-index: 999 !important"
      />
    </b-col>
    <b-col xl="9" md="9" sm="12">
      <b-row>
        <b-col v-if="vehiclesStatus === 'fetching'" class="mt-2">
          <b-row>
            <b-col
              v-for="(data, index) in [1, 2, 3, 4, 5, 6]"
              :key="index"
              lg="4"
              md="6"
              sm="12"
              class="mt-3"
            >
              <b-card no-body img-top>
                <b-skeleton-img card-img="top" aspect="3:1" />
                <b-card-body>
                  <b-skeleton-table
                    :rows="5"
                    :columns="1"
                    :table-props="{ bordered: true, striped: true }"
                  />
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-col>

        <b-col
          v-else-if="vehicles.data && !vehicles.data.length"
          class="mt-2 col-lg-4 col-centered"
        >
          <b>No records to show</b>
        </b-col>
        <template v-else>
          <b-col cols="12">
            <!-- sync button -->
            <b-row class="mb-1">
              <b-col class="text-left">
                <small v-if="lastUpdate != null"
                  >Last update: {{ lastUpdate | formatDateTime }}</small
                >
                <br />
                <b-button
                  v-if="userIsAdmin"
                  :disabled="synVehiclesIsLoading"
                  variant="primary"
                  class="mr-2"
                  @click="syncVehicles()"
                >
                  <!-- loader -->
                  <b-spinner
                    v-if="synVehiclesIsLoading"
                    small
                    label="Syncing..."
                  ></b-spinner>
                  <span> Sync Vehicles <b-icon icon="arrow-repeat" /> </span>
                </b-button>
                <b-button
                  v-if="userIsAdmin"
                  :disabled="downloadExcelIsLoading"
                  variant="success"
                  class="mr-2"
                  @click="getVehiclesExcel()"
                >
                  <!-- loader -->
                  <b-spinner
                    v-if="downloadExcelIsLoading"
                    small
                    label="Syncing..."
                  ></b-spinner>
                  <span>
                    Download excel
                    <b-icon icon="file-earmark-excel" />
                  </span>
                </b-button>
              </b-col>
            </b-row>
            <b-tabs v-model="listType" content-class="mt-3" class="mbn-3">
              <b-tab title="">
                <template #title>
                  <b-icon icon="grid"></b-icon>
                </template>
                <b-row>
                  <b-col
                    class="mt-2"
                    xl="4"
                    md="4"
                    sm="12"
                    v-for="vehicle in vehicles.data"
                    :key="vehicle.id"
                  >
                    <!--           <VehicleCard :vehicle="vehicle" :showCRModal="true" />
 -->
                    <div class="mx-auto component-wrapper">
                      <div
                        v-if="
                          vehicle.assetSubstatusCode == 'ÉCHANGE À LEASE' ||
                          vehicle.assetSubstatusCode == 'ÉCHANGE À WHO' ||
                          vehicle.assetSubstatusCode == 'ÉCHANGE WHO RUSH' ||
                          vehicle.assetSubstatusCode == 'ÉCHANGE A WHO US'
                        "
                        class="coming-soon-tag"
                      >
                        Coming soon
                      </div>
                      <div
                        v-if="vehicle.websiteAssetSaleStatus == 'NEW'"
                        class="flame-new"
                      >
                        🔥
                      </div>
                      <VehicleCard
                        :vehicle="vehicle"
                        :typeList="'grid'"
                        @changeTabNumber="changeTabNumber(1)"
                      />
                    </div>
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab title="">
                <template #title>
                  <b-icon icon="list"></b-icon>
                </template>
                <b-col cols="12" class="p-0">
                  <VehiclesListView
                    :vehicles="vehicles.data"
                    :typeList="'list'"
                    :refresh="getVehicles"
                    @changeTabNumber="changeTabNumber(1)"
                  />
                </b-col>
              </b-tab>
            </b-tabs>
          </b-col>
        </template>
      </b-row>
    </b-col>
    <VehicleDetailsSidebar />
    <VinInformationSidebar />
  </b-row>
</template>

<script>
import { mapState } from "vuex";
import Search from "./Search";
/* import VehicleCard from "./VehicleCard";
 */ import VehicleDetailsSidebar from "./components/VehicleDetailsSidebar";
import VehicleCard from "./VehicleCard/VehicleCard.vue";
import { listVehiclesMixin } from "./mixins";
import {
  SET_VEHICLES_FILTERS,
  GET_VEHICLES,
  POST_SYNC_VEHICLES,
  GET_VEHICLES_EXCEL,
} from "./actions";
import VinInformationSidebar from "./VinInformationSidebar.vue";
import VehiclesListView from "./VehiclesListView.vue";
export default {
  props: ["isBuyerView", "userIsAdmin", "userIsAgent", "companyIsPending"],
  components: {
    Search,
    VehicleCard,
    VehicleDetailsSidebar,
    VinInformationSidebar,
    VehiclesListView,
  },
  mixins: [listVehiclesMixin],
  data() {
    return {
      missingInfoSelected: false,
      listType: 1,
    };
  },
  computed: {
    ...mapState({
      vehicles: (state) => state.adminStore.vehicles.listStore.vehicles.data,
      pagination: (state) => state.adminStore.vehicles.listStore.vehicles.data,
      vehiclesStatus: (state) =>
        state.adminStore.vehicles.listStore.vehicles.status,
      statusFilter: (state) =>
        state.adminStore.vehicles.listStore.vehicles.filters.status,
      statusFilterInDraft: (state) =>
        state.adminStore.vehicles.listStore.vehicles.filters.status === "Draft",
      synVehiclesIsLoading: (state) =>
        state.adminStore.vehicles.listStore.syncVehicles.status === "fetching",
      downloadExcelIsLoading: (state) =>
        state.adminStore.vehicles.listStore.downloadExcel.status === "fetching",
      lastUpdate: (state) =>
        state.adminStore.vehicles.listStore.vehicleFilters.data.lastUpdate,
    }),
  },
  methods: {
    getVehicles() {
      this.$store
        .dispatch(GET_VEHICLES, {})
        .then(() => {})
        .catch(() => {
          this.$root.$bvToast.toast("Could not get the vehicle list", {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    missingInfo() {
      this.missingInfoSelected = !this.statusFilterInDraft
        ? true
        : !this.missingInfoSelected;
      const status = this.missingInfoSelected ? "Draft" : null;
      this.$store.commit(SET_VEHICLES_FILTERS, {
        reset: true,
        ...{ fields: { status } },
      });
      this.$refs.searchVehiclesComponent.clearElementsInSearch();
      this.searchVehiclesByTabMenu();
    },
    orderVehicles() {
      let filter = { new: true };
      this.$store.commit(SET_VEHICLES_FILTERS, { fields: { ...filter } });
      this.searchVehiclesByTabMenu();
    },
    changeTabNumber(tabNumber) {
      this.$emit("changeTabNumber", tabNumber);
    },
    syncVehicles() {
      this.$store.dispatch(POST_SYNC_VEHICLES, {}).then(() => {
        this.$root.$bvToast
          .toast("Vehicles synced successfully", {
            title: "Success",
            variant: "success",
          })
          .catch(() => {
            this.$root.$bvToast.toast("Could not sync vehicles", {
              title: "Warning",
              variant: "warning",
            });
          });
      });
    },
    getVehiclesExcel() {
      this.$store.dispatch(GET_VEHICLES_EXCEL, {}).then((response) => {
        const blob = new Blob([response], {
          type: "application/vnd.ms-excel",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download =
          this.formatFileName("vehicles") + ".xls" || "ficher.xls";
        link.click();
      });
    },
    formatFileName(type) {
      const date = new Date();
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${type}-${year}-${month}-${day}`;
    },
  },
  beforeMount() {
    if (this.statusFilterInDraft) this.missingInfoSelected = true;
  },
};
</script>

<style>
@media screen and (max-width: 300px) {
  .btn-missing-info {
    margin-bottom: 10px;
  }
}
.component-wrapper {
  position: relative;
  display: inline-block;
  padding: 0px;
  border: 1px solid #ddd;
  overflow: hidden;
}

.coming-soon-tag {
  position: absolute;
  top: 20px;
  left: -25px;
  background-color: #f39c12;
  color: white;
  font-weight: bold;
  padding: 5px 20px;
  font-size: 14px;
  transform: rotate(-45deg);
  z-index: 10;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.flame-new {
  position: absolute;
  top: -1px;
  right: 5px;
  font-weight: bold;
  font-size: 20px;
  z-index: 10;
}
</style>
