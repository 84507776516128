<template>
  <div>
    <h4>Company information</h4>
    <b-row>
      <b-col sm="12" md="6" lg="6" xl="6">
        <label for="">
          <b>Commercial name:</b> <br />
          <i>
            <i> {{ preCompany.commercialName || "----" }}</i>
          </i>
        </label>
        <!--  <b-form-group label="Commercial name ">
          <b-form-input
            v-model="preCompany.commercialName"
            readonly
          ></b-form-input>
        </b-form-group> -->
      </b-col>
      <b-col sm="12" md="6" lg="6" xl="6">
        <label for="">
          <b>Company name:</b> <br />
          <i
            ><i> {{ preCompany.companyName || "----" }}</i>
          </i>
        </label>
        <!--    <b-form-group label="Company Name">
          <b-form-input
            v-model="preCompany.companyName"
            readonly
          ></b-form-input>
        </b-form-group> -->
      </b-col>
      <!-- Fax -->
      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <label for="">
          <b>Fax number:</b> <br />
          <i> {{ preCompany.faxNumber || "----" }}</i>
        </label>
        <!--         <b-form-group label-size="sm">
          <template slot="label">Fax number </template>
          <b-form-input
            size="md"
            id="faxNumber-input"
            name="faxNumber-input"
            v-model="preCompany.faxNumber"
            readonly
          >
          </b-form-input>
        </b-form-group> -->
      </b-col>

      <!-- Code -->
      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <label for="">
          <b>Company ID:</b> <br />
          <i> {{ preCompany.companyId || "----" }}</i>
        </label>
        <!--         <b-form-group label-size="sm">
          <template slot="label">ID</template>
          <b-form-input
            size="md"
            id="code-input"
            name="code-input"
            v-model="preCompany.companyId"
            maxlength="100"
            readonly
          >
          </b-form-input>
        </b-form-group> -->
      </b-col>
      <b-col sm="12" md="12" lg="12" xl="12">
        <label for="">
          <b>Address line 2:</b> <br />
          <i> {{ preCompany.addressLine2 || "----" }}</i>
        </label>
        <!--         <b-form-group label-size="md">
          <template slot="label"> Address line 2 </template>
          <b-form-input
            v-model="preCompany.addressLine2"
            name="addressLine2"
            maxlength="200"
            readonly
          />
        </b-form-group> -->
      </b-col>

      <b-col sm="12" md="12" lg="6" xl="6">
        <label for="">
          <b>Street:</b> <br />
          <i> {{ preCompany.street || "----" }}</i>
        </label>
        <!--         <b-form-group label-size="md">
          <template slot="label"> Street </template>
          <b-form-input
            v-model="preCompany.street"
            size="sm"
            name="street"
            maxlength="65"
            readonly
          />
        </b-form-group> -->
      </b-col>

      <b-col sm="12" md="12" lg="6" xl="6">
        <label for="">
          <b>ZIP postal code:</b> <br />
          <i> {{ preCompany.zip || "----" }}</i>
        </label>
        <!--   <b-form-group label-size="md">
          <template slot="label"> ZIP postal code </template>
          <b-form-input
            v-model="preCompany.zip"
            size="sm"
            name="zip"
            maxlength="10"
            readonly
          />
        </b-form-group> -->
      </b-col>
      <b-col sm="12" md="12" lg="6" xl="6">
        <label for="">
          <b>State:</b> <br />
          <i> {{ preCompany.state || "----" }}</i>
        </label>
        <!--         <b-form-group label-size="md">
          <template slot="label"> State </template>
          <b-form-input
            v-model="preCompany.state"
            disabled
            size="sm"
            name="state"
            maxlength="250"
            readonly
          />
        </b-form-group> -->
      </b-col>

      <b-col sm="12" md="12" lg="6" xl="6">
        <label for="">
          <b>City:</b> <br />
          <i> {{ preCompany.city || "----" }}</i>
        </label>
        <!--         <b-form-group label-size="md">
          <template slot="label"> City </template>
          <b-form-input
            v-model="preCompany.city"
            disabled
            size="sm"
            name="city"
            maxlength="250"
            readonly
          />
        </b-form-group> -->
      </b-col>

      <b-col sm="12" md="12" lg="6" xl="6">
        <label for="">
          <b>Website:</b> <br />
          <i> {{ preCompany.webSite || "----" }}</i>
        </label>
        <!--   <b-form-group label-size="md">
          <template slot="label"> Website </template>
          <b-form-input
            v-model="preCompany.webSite"
            size="sm"
            name="webSite"
            placeholder="www.yourSite.com"
            maxlength="65"
            disabled
          />
        </b-form-group> -->
      </b-col>
      <b-col sm="12" md="12" lg="6" xl="6">
        <label for="">
          <b>GST/HST:</b> <br />
          <i> {{ preCompany.gst || "----" }}</i>
        </label>
        <!--         <b-form-group label-size="md">
          <template slot="label"> GST/HST </template>
          <b-form-input
            v-model="preCompany.gst"
            size="sm"
            name="gst"
            readonly
          />
        </b-form-group> -->
      </b-col>
      <b-col sm="12" md="12" lg="6" xl="6">
        <label for="">
          <b>QST:</b> <br />
          <i> {{ preCompany.qst || "----" }}</i>
        </label>
        <!--         <b-form-group label-size="md">
          <template slot="label"> QST </template>
          <b-form-input
            v-model="preCompany.qst"
            size="sm"
            name="qst"
            readonly
          />
        </b-form-group> -->
      </b-col>

      <!-- NEQ -->
      <b-col sm="12" md="12" lg="6" xl="6">
        <label v-if="typeNumber == 'NEQ'" for="">
          <b>NEQ:</b> <br />
          <i> {{ preCompany.corporationNumber || "----" }}</i>
        </label>
        <label v-else for="">
          <b>Business number:</b> <br />
          <i> {{ preCompany.corporationNumber || "----" }}</i>
        </label>

        <!--     <b-form-group label-size="md">
          <template slot="label">
            <b-form-radio-group
              id="radio-group-2"
              v-model="typeNumber"
              name="radio-sub-component1"
            >
              <b-form-radio value="NEQ">NEQ</b-form-radio>
              <b-form-radio value="BusinessNumber">
                Business number
              </b-form-radio>
            </b-form-radio-group>
          </template>
          <b-form-input
            size="sm"
            name="corporationNumber"
            maxlength="10"
            v-model="preCompany.corporationNumber"
            readonly
          />
        </b-form-group> -->
      </b-col>

      <b-col sm="12" md="12" lg="6" xl="6">
        <label for="">
          <b>Dealer license:</b> <br />
          <i> {{ preCompany.dealerLicense || "----" }}</i>
        </label>
        <!--         <b-form-group label-size="md">
          <template slot="label"> Dealer license </template>

          <b-form-input
            v-model="preCompany.dealerLicense"
            size="sm"
            name="dealerLicense"
            maxlength="50"
            readonly
          />
        </b-form-group> -->
      </b-col>

      <b-col sm="12" md="12" lg="6" xl="6">
        <label for="">
          <b>Billing Contact name :</b> <br />
          <i> {{ preCompany.billingContactName || "----" }}</i>
        </label>
        <!--         <b-form-group label-size="md">
          <template slot="label"> Billing Contact name </template>
          <b-form-input
            v-model="preCompany.billingContactName"
            size="sm"
            name="billingContactName"
            maxlength="100"
            readonly
          />
        </b-form-group> -->
      </b-col>

      <b-col sm="12" md="12" lg="6" xl="6">
        <label for="">
          <b>Billing contact email :</b> <br />
          <i> {{ preCompany.billingContactEmail || "----" }}</i>
        </label>
        <!--         <b-form-group label-size="md">
          <template slot="label"> Billing contact email </template>
          <b-form-input
            v-model="preCompany.billingContactEmail"
            size="sm"
            name="billingContactEmail"
            maxlength="100"
            placeholder="example@gmail.com"
            readonly
          />
        </b-form-group> -->
      </b-col>

      <b-col sm="12" md="12" lg="6" xl="6">
        <label for="">
          <b>Billing contact phone :</b> <br />
          <i> {{ preCompany.billingContactMobilePhone || "----" }}</i>
        </label>
        <!--         <b-form-group label-size="md">
          <template slot="label"> Billing contact mobile phone </template>
          <b-form-input
            v-model="preCompany.billingContactMobilePhone"
            disabled
          />
        </b-form-group> -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    preCompany: {
      type: Object,
      default: () => {},
    },
  },
  name: "PreCompanyForm",
  ...mapState({
    companies: (state) =>
      state.adminStore.assignCompany.companiesToAssign.data || [],
  }),

  data() {
    return {
      typeNumber: "NEQ",

      options: {
        type: "tel",
        validCharactersOnly: true,
        maxlength: 18,
      },
      telDdOptions: {
        showDialCodeInList: true,
        showFlags: true,
        showDialCodeInSelection: false,
      },
    };
  },

  methods: {},
};
</script>

<style>
</style>