import vehicles from "./vehicles/store";
import users from "./users/store";
import notifications from "./notifications/store";
import agents from "./agent/store";
import assignCompany from "./assignCompany/store";
import companies from "./companies/store"
export default {
  state: {},
  actions: {},
  mutations: {},
  modules: {
    vehicles,
    users,
    notifications,
    agents,
    assignCompany,
    companies
  },
};
