<template>
  <b-modal
    ref="previewImagesModal"
    v-model="showModal"
    title="Confirm"
    hide-footer
    no-close-on-backdrop
    size="xl"
  >
    <div>
      <div class="image-list">
        <div v-for="(image, index) in images" :key="index" class="image-item">
          <b-dropdown
            dropright
            menu-class="b-dropdown-menu"
            class="p-0 bg-white"
          >
            <template #button-content>
              <img v-if="image.url" :src="image.url" class="preview-img" />
              <img v-else :src="image" class="preview-img" />
              <br />
            </template>

            <b-dropdown-item
              v-for="(part, key) in vehiclePartsWithNoImage"
              :key="key"
              @click="associateImageToPart(image, part.id)"
              >{{ part.label }}</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </div>
      <b-row>
        <b-col
          md="2"
          lg="2"
          v-for="(part, key) in vehicleParts"
          :key="key"
          class="mb-5"
        >
          <h6>{{ part.label }}</h6>
          <div class="image-list">
            <!--   <img
            v-if="part.image.length == 0"
            :src="part.image[1].url"
            class="preview-img"
          /> -->

            <div v-if="part.image" class="image-item">
              <img
                v-if="part.image.url || part.image"
                :src="part.image.url ? part.image.url : part.image"
                class="preview-img responsive"
              />
              <button
                v-if="!part.image.title"
                class="delete-btn cursor-pointer"
                @click="deleteImageFromPart(part)"
              >
                X
              </button>
            </div>
          </div>
        </b-col>
      </b-row>
      <!-- button align right to confirm -->
      <b-button variant="primary" class="float-right" @click="updateOrder"
        >Confirm</b-button
      >
    </div>
  </b-modal>
</template>

<script>
export default {
  components: {},
  props: {
    vehicleImages: {
      type: Array,
      required: false,
    },
    isUpdatePreviewImages: {
      type: Boolean,
      required: false,
    },
    showModal: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      vehicleParts: [
        {
          label: "Front",
          image: null,
          id: 1,
        },
        {
          label: "Left",
          image: null,
          id: 2,
        },
        {
          label: "Rear",
          image: null,
          id: 3,
        },
        {
          label: "Right",
          image: null,
          id: 4,
        },
        {
          label: "Roof",
          image: null,
          id: 5,
        },
        {
          label: "Tires",
          image: null,
          id: 6,
        },
        {
          label: "Interior",
          image: null,
          id: 7,
        },
        {
          label: "VIN Sticker",
          image: null,
          id: 8,
        },
        {
          label: "Dash Panel",
          image: null,
          id: 9,
        },
        {
          label: "Odometer",
          image: null,
          id: 10,
        },
        {
          label: "Dashboard-Wheel",
          image: null,
          id: 11,
        },
        {
          label: "Cargo Area",
          image: null,
          id: 12,
        },
        {
          label: "Engine",
          image: null,
          id: 13,
        },
        {
          label: "Keys-Fobs",
          image: null,
          id: 14,
        },
        {
          label: "Other",
          image: null,
          id: 15,
        },
        {
          label: "Cluster",
          image: null,
          id: 16,
        },
        {
          label: "Tires Label",
          image: null,
          id: 17,
        },
        {
          label: "CAB",
          image: null,
          id: 89,
        },

        {
          label: "Driver Front",
          image: null,
          id: 90,
        },
        {
          label: "Passenger Front",
          image: null,
          id: 91,
        },
        {
          label: "Driver Rear",
          image: null,
          id: 92,
        },
        {
          label: "Passenger Rear",
          image: null,
          id: 93,
        },
      ],

      images: [...this.vehicleImages],
      emptyImage: {
        url:
          "https://static-00.iconduck.com/assets.00/insert-image-icon-512x482-1lsoxqyx.png",
        title: "empty",
      },
    };
  },

  computed: {
    imagesOfVehicleFromProps() {
      return this.images;
    },
    vehiclePartsWithNoImage() {
      return this.vehicleParts.filter(
        (part) => part.image == {} || part.image?.title === "empty"
      );
    },
  },
  methods: {
    onFileChange(event) {
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.images.push({ url: e.target.result, file: files[i], part: "" });
        };
        reader.readAsDataURL(files[i]);
      }
    },
    updateOrder() {
      const notEmptyImages = this.vehicleParts.filter(
        (part) => part.image.title !== "empty"
      );
      this.$emit("onConfirmImages", notEmptyImages);
    },
    checkIfPartHasImage(part) {
      return part.image.length > 0;
    },
    checkIfEmptyImage(image) {
      return image.title === "empty";
    },

    searchArrayIndex(event) {
      let index = null;
      this.vehicleParts.forEach((part, key) => {
        part.image.forEach((image) => {
          if (image.url === event.element.url) {
            index = key;
          }
        });
      });
      return index;
    },

    isEmptyArray(array) {
      return array.length === 0;
    },
    async fillEmptyImages() {
      await this.formatImagesPreCharged();
      this.vehicleParts.forEach((part) => {
        if (part.image == null) {
          part.image = null;
          part.image = this.emptyImage;
        }
      });
    },
    loadVehicleImages() {},

    associateImageToPart(image, partId) {
      const partIndex = this.vehicleParts.findIndex(
        (part) => part.id === partId
      );

      const imageIndex = this.images.findIndex((img) => img === image);
      this.vehicleParts[partIndex].image = this.images[imageIndex];
      this.images.splice(imageIndex, 1);
    },
    deleteImageFromPart(part) {
      const partIndex = this.vehicleParts.findIndex(
        (vehiclePart) => vehiclePart.id === part.id
      );
      this.images.push(part.image);
      this.vehicleParts[partIndex].image = this.emptyImage;
    },

    async formatImagesPreCharged() {
      console.log("formatImagesPreCharged images", this.images);
      this.vehicleParts.forEach((part) => {
        part.image = this.images.find((image) => image.part === part.id);
        if (part.image == undefined) {
          part.image = this.emptyImage;
        }
      });
      console.log("formatImagesPreCharged", this.vehicleParts);
      if (this.isUpdatePreviewImages) {
        this.images = [];
      }
    },
  },
  mounted() {
    this.formatImagesPreCharged();
  },
};
</script>

<style>
.image-list {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.image-item {
  width: 160px;
  text-align: center;
}

.preview-img {
  width: 100%;
  height: 100px;
  /*   object-fit: cover; */
  border-radius: 5px;
}

.part-labels {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.part-labels label {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
}

.delete-btn {
  position: absolute;
  top: 32px;
  right: 20px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 2.5px 6px;
  font-size: 10px;
}

.b-dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}

.btn-secondary {
  background-color: #f8f9fa !important;
  color: #495057 !important;
  border-color: #f8f9fa !important;
}
</style>
