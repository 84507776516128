import Vue from "vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "@/sass/main.scss";
import VueProgressBar from "vue-progressbar";
import Vuelidate from "vuelidate";
import VuePlaceAutocomplete from "vue-place-autocomplete";
import Photoswipe from "vue-pswipe";
import VueBarcode from "@chenfengyuan/vue-barcode";
import VueCompositionAPI from "@vue/composition-api";
import VueScreenSize from "vue-screen-size";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import VueCookies from "vue-cookies";
import VueClipboard from "vue-clipboard2";

/* import Hotjar from "vue-hotjar";
 */
/* Vue.use(Hotjar, {
  id: "2863999", // Hotjar Site ID
  snippetVersion: 6,
  isProduction: true,
}); */

const progressOptions = {
  color: "#007bff",
  failedColor: "#dc3545",
  thickness: "5px",
};

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueProgressBar, progressOptions);
Vue.use(Vuelidate);
Vue.use(VuePlaceAutocomplete);
Vue.use(Photoswipe);
Vue.use(VueCompositionAPI);
Vue.component("vue-barcode", VueBarcode);
Vue.use(VueScreenSize);
Vue.use(VueTelInput);
Vue.use(VueCookies);
Vue.use(VueClipboard);
Vue.use(Photoswipe)

Vue.component('vue-multiselect', window.VueMultiselect.default)
Vue.mixin({
  computed: {
    witdhResponsive() {
      return this.$vssWidth > 1280
        ? "25%"
        : this.$vssWidth <= 576
          ? "100%"
          : "50%";
    },
  },
  methods: {
    lowerCaseFormatter(value) {
      return value.toLowerCase();
    },
    toastMessage(message, title, variant) {
      this.$root.$bvToast.toast(message, {
        title: title,
        variant: variant,
      });
    },
  },
});
