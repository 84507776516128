<template>
  <div id="app">
    <component v-cloak :is="layout"></component>
    <vue-progress-bar></vue-progress-bar>
  </div>
</template>
<script>
const defaultLayout = "default";

export default {
  name: "App",
  computed: {
    layout() {
      return (this.$route.meta.layout || defaultLayout) + "-layout";
    },
  },
};
</script>
<style>
[v-cloak] {
  display: none;
}
</style>
