import * as constants from "./actions";
import axios from "@/plugins/axios.js";

function getDefaultState() {
  return {
    dealer: {
      status: null,
      data: null,
      error: null,
    },
    emailValidation: {
      status: null,
      data: null,
      error: null,
    },
  };
}

const actions = {
  [constants.POST_DEALER]: async ({ commit }, params) => {
    try {
      commit(constants.POST_DEALER);
      const response = await axios.post("user/createUserTransCanada", params);
      commit(constants.POST_DEALER_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_DEALER_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_VALIDATE_EMAIL]: async ({ commit }, params) => {
    try {
      commit(constants.POST_VALIDATE_EMAIL);
      const response = await axios.post("user/validate-repeat-email", params);
      commit(constants.POST_VALIDATE_EMAIL_SUCCESS, response);
      return response;
    } catch (error) {
      if (error.response.status == 400) {
        commit(constants.POST_VALIDATE_EMAIL_SUCCESS, error.response.data);
        return;
      }
      commit(constants.POST_VALIDATE_EMAIL_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.POST_DEALER]: (state) => {
    state.dealer.status = "fetching";
    state.dealer.error = null;
  },
  [constants.POST_DEALER_SUCCESS]: (state, data) => {
    state.dealer.status = "success";
    state.dealer.error = null;
    state.dealer.data = data;
  },
  [constants.POST_DEALER_ERROR]: (state, error) => {
    state.dealer.status = "error";
    state.dealer.error = error;
  },
  [constants.POST_VALIDATE_EMAIL]: (state) => {
    state.emailValidation.status = "fetching";
    state.emailValidation.error = null;
  },
  [constants.POST_VALIDATE_EMAIL_SUCCESS]: (state, data) => {
    state.emailValidation.status = "success";
    state.emailValidation.error = null;
    state.emailValidation.data = data;
  },
  [constants.POST_VALIDATE_EMAIL_ERROR]: (state, error) => {
    state.emailValidation.status = "error";
    state.emailValidation.error = error;
  },
};

export default {
  state: getDefaultState(),
  actions,
  mutations,
};
