<template>
  <div class="text-center mt-5">
    <b-button variant="success" @click="downloadFile()"> Download </b-button>
  </div>
</template>

<script>
export default {
  methods: {
    downloadFile() {
      const url =
        "https://storagecanam.nyc3.digitaloceanspaces.com/Prod/SLAA%20Sharp_20230524_140712.pdf";
      window.open(url, "_blank");
    },
  },
};
</script>

<style>
</style>
