export const defaultFields = [
  {
    key: "vinNumber",
    label: "Vin Number",
    sortable: true,
  },
  {
    key: "stockNumber",
    label: "Stock Number",
    sortable: true,
  },
  {
    key: "user",
    label: "Agent",
    sortable: true,
  },
];

export const optionalFields = [
  {
    key: "checks",
    label: "Checks",
    class: "text-center",
  },
  {
    key: "conditionalReports",
    label: "CR",
    class: "text-center",
  },
  {
    key: "notes",
    label: "Notes",
    class: "text-center",
  },
  {
    key: "recall",
    label: "Recall",
    class: "text-center",
  },
  {
    key: "carfax",
    label: "Carfax",
    class: "text-center",
  },
  {
    key: "exportLogistic",
    label: "Export Logistic",
    class: "text-center",
  },
  {
    key: "exportPaperwork",
    label: "Export Paperwork",
    class: "text-center",
  },
  {
    key: "registry",
    label: "Registry",
    class: "text-center",
  },
  {
    key: "sold",
    label: "Sold",
    class: "text-center",
  },
  {
    key: "visualInspection",
    label: "Visual Inspection",
    class: "text-center",
  },
];
