<template>
  <b-modal
    id="vehicleSaleInformationModal"
    title="Sale information"
    centered
    @show="getBuyers()"
    @hidden="resetForm()"
  >
    <b-form>
      <b-form-group label="Price:">
        <Money
          class="form-control"
          @change="setValue('price', $event)"
          :state="validateState('price')"
          v-model.trim="$v.form.price.$model"
          v-bind="money"
          aria-describedby="input-price-feedback"
        />
        <b-form-invalid-feedback id="input-price-feedback">
          This is a required field .
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Buyer:">
        <b-form-select
          @change="setValue('buyer', $event)"
          :state="validateState('buyer')"
          v-model.trim="$v.form.buyer.$model"
        >
          <b-form-select-option
            v-for="(buyer, i) of buyers.data"
            :key="i"
            :value="buyer.id"
          >
            {{ buyer.firstName }} {{ buyer.lastName }}
          </b-form-select-option>
        </b-form-select>
      </b-form-group>
      <b-form-group label="Date:">
        <b-form-datepicker
          @change="setValue('registerDate', $event)"
          :state="validateState('registerDate')"
          v-model.trim="$v.form.registerDate.$model"
          :max="currentDate"
          locale="en"
        />
      </b-form-group>
    </b-form>
    <template #modal-footer>
      <div class="w-100 text-right">
        <b-button
          :disabled="$v.form.$invalid || saveInfo.status === 'fetching'"
          variant="success"
          @click="saveSaleInfo()"
        >
          Submit
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapState } from "vuex";
import { Money } from "v-money";
import { required } from "vuelidate/lib/validators";
import { GET_BUYERS, POST_SAVE_VEHICLE_SALE_INFO } from "./actions";
import { generalMixin } from "@/modules/mixin";
import { registryVehicleMixin } from "../mixins";
export default {
  props: ["vehicleId"],
  components: { Money },
  mixins: [registryVehicleMixin, generalMixin],
  computed: {
    ...mapState({
      saveInfo: (state) =>
        state.adminStore.vehicles.carRegistry.componentsStore
          .saveVehicleSaleInfo,
      buyers: (state) =>
        state.adminStore.vehicles.carRegistry.componentsStore.buyers,
    }),
    currentDate() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear();

      today = yyyy + "-" + mm + "-" + dd;
      return today;
    },
  },
  data() {
    return {
      selecciona: null,
      form: {
        price: 0.0,
        buyer: null,
        registerDate: null,
        vehiclesId: null,
      },
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "USD$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
    };
  },
  validations: {
    form: {
      price: {
        required,
        isDiffZero: (value) => (value === 0.0 ? false : true),
      },
      buyer: { required },
      registerDate: { required },
    },
  },
  methods: {
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        price: 0.0,
        buyer: null,
        registerDate: null,
        vehiclesId: null,
      };
    },
    getBuyers() {
      this.$store
        .dispatch(GET_BUYERS)
        .then(() => {})
        .catch(() => {
          this.toastMessage(
            "The Buyers list could not be obtained",
            "Warning",
            "warning"
          );
        });
    },
    saveSaleInfo() {
      if (this.$v.form.$invalid) return;
      this.form.vehiclesId = parseInt(this.vehicleId);
      this.$store
        .dispatch(POST_SAVE_VEHICLE_SALE_INFO, this.form)
        .then(() => {
          this.toastMessage(
            "Information saved and vehicle status changed to Sold",
            "Success",
            "success"
          );
          this.sendToVehicleList();
        })
        .catch(() => {
          this.toastMessage(
            "The information could not be saved",
            "Warning",
            "warning"
          );
        });
    },
  },
  beforeMount() {
    this.getBuyers();
  },
};
</script>

<style></style>
