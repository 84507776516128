import * as constants from "./actions";
import axios from "@/plugins/axios.js";
function getDefaultState() {
  return {
    parts: {
      status: null,
      data: null,
      error: null,
    },
    uploadPhoto: {
      status: null,
      data: null,
      error: null,
    },
    deletePhoto: {
      status: null,
      data: null,
      error: null,
    },
  };
}

const actions = {
  [constants.GET_VEHICLE_PARTS]: async ({ commit }) => {
    try {
      commit(constants.GET_VEHICLE_PARTS);
      const response = await await axios.get(`vehicle/part`);
      commit(constants.GET_VEHICLE_PARTS_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_VEHICLE_PARTS_ERROR, error);
      throw error.response;
    }
  },
  [constants.POST_UPLOAD_PHOTO]: async ({ commit }, data) => {
    try {
      commit(constants.POST_UPLOAD_PHOTO);
      const headers = {
        "Content-Type": "application/form-data",
      };
      const response = await axios.post(`vehicle/assignPhoto`, data, {
        headers,
      });
      commit(constants.POST_UPLOAD_PHOTO_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.POST_UPLOAD_PHOTO_ERROR, error);
      throw error.response;
    }
  },
  [constants.DELETE_PHOTO]: async ({ commit }, id) => {
    try {
      commit(constants.DELETE_PHOTO);
      const response = await axios.delete("vehicle/deletePhoto", {
        data: { id },
      });
      commit(constants.DELETE_PHOTO_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.DELETE_PHOTO_ERROR, error);
      throw error.response;
    }
  },
  [constants.DELETE_EXTERNAL_PHOTO]: async ({ commit }, id) => {
    try {
      commit(constants.DELETE_EXTERNAL_PHOTO);
      const response = await axios.delete("vehicle/deleteExternalPhoto", {
        data: { id },
      });
      commit(constants.DELETE_EXTERNAL_PHOTO_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.DELETE_EXTERNAL_PHOTO_ERROR, error);
      throw error.response;
    }
  },
};

const mutations = {
  [constants.GET_VEHICLE_PARTS]: (state) => {
    state.parts.status = "fetching";
    state.parts.error = null;
  },
  [constants.GET_VEHICLE_PARTS_SUCCESS]: (state, data) => {
    state.parts.status = "success";
    state.parts.data = data;
    state.parts.error = null;
  },
  [constants.GET_VEHICLE_PARTS_ERROR]: (state, error) => {
    state.parts.status = "error";
    state.parts.error = error;
  },
  [constants.POST_UPLOAD_PHOTO]: (state) => {
    state.uploadPhoto.status = "fetching";
    state.uploadPhoto.error = null;
  },
  [constants.POST_UPLOAD_PHOTO_SUCCESS]: (state, data) => {
    state.uploadPhoto.status = "success";
    state.uploadPhoto.data = data;
    state.uploadPhoto.error = null;
  },
  [constants.POST_UPLOAD_PHOTO_ERROR]: (state, error) => {
    state.uploadPhoto.status = "error";
    state.uploadPhoto.error = error;
  },
  [constants.DELETE_PHOTO]: (state) => {
    state.deletePhoto.status = "fetching";
    state.deletePhoto.error = null;
  },
  [constants.DELETE_PHOTO_SUCCESS]: (state, data) => {
    state.deletePhoto.status = "success";
    state.deletePhoto.data = data;
    state.deletePhoto.error = null;
  },
  [constants.DELETE_PHOTO_ERROR]: (state, error) => {
    state.deletePhoto.error = "error";
    state.deletePhoto.error = error;
  },
  [constants.DELETE_EXTERNAL_PHOTO]: (state) => {
    state.deletePhoto.status = "fetching";
    state.deletePhoto.error = null;
  },
  [constants.DELETE_EXTERNAL_PHOTO_SUCCESS]: (state, data) => {
    state.deletePhoto.status = "success";
    state.deletePhoto.data = data;
    state.deletePhoto.error = null;
  },
  [constants.DELETE_EXTERNAL_PHOTO_ERROR]: (state, error) => {
    state.deletePhoto.error = "error";
    state.deletePhoto.error = error;
  },
};

export default {
  state: getDefaultState(),
  actions,
  mutations,
};
